import { get, put } from 'data/services/apiService';

const GET_ADMIN_USERS_REQUEST = 'GET_ADMIN_USERS_REQUEST';
const GET_ADMIN_USERS_SUCCESS = 'GET_ADMIN_USERS_SUCCESS';
const GET_ADMIN_USERS_ERROR = 'GET_ADMIN_USERS_ERROR';

const GET_ADMIN_USER_REQUEST = 'GET_ADMIN_USER_REQUEST';
const GET_ADMIN_USER_SUCCESS = 'GET_ADMIN_USER_SUCCESS';

const UPDATE_ADMIN_USER_REQUEST = 'UPDATE_ADMIN_USER_REQUEST';
const UPDATE_ADMIN_USER_SUCCESS = 'UPDATE_ADMIN_USER_SUCCESS';
const UPDATE_ADMIN_USER_ERROR = 'UPDATE_ADMIN_USER_ERROR';


const initialState = {
	data: null,
	user: null,
	isLoading: false,
	isLoadingUser: false,
	isUpdating: false,
	hasError: false,
	error: null,
};

export const fetchAdminUsers = (sort='name|asc', page=1, perPage=10) => (dispatch, getState) => {
	let state = getState();
	let url = `https://api.violerts.com/api/admin/users?sort=${sort}&page=${page}&per_page=${perPage}`;
	dispatch(getAdminUsersRequest());
	return get({
		dispatch,
		url,
		authToken: state.user.authToken,
		onSuccess: (result) => {
			dispatch(getAdminUsersSuccess(result));
		},
		onError: error => dispatch(getAdminUsersError(error)),
	});
};

export const fetchAdminUser = (userId) => (dispatch, getState) => {
	let state = getState();
	let url = `https://api.violerts.com/api/admin/users/${userId}`;
	dispatch(getAdminUserRequest());
	return get({
		dispatch,
		url,
		authToken: state.user.authToken,
		onSuccess: (result) => {
			dispatch(getAdminUserSuccess(result));
		},
		onError: error => dispatch(getAdminUsersError(error)),
	});
};


export const editAdminUser = (userId, userData) => (dispatch, getState) => {
	let state = getState();
	let url = `https://api.violerts.com/api/admin/users/${userId}`;
	dispatch(updateAdminUserRequest());

	return put({
		dispatch,
		url,
		authToken: state.user.authToken,
		data: userData,
		onSuccess: (result) => {
			dispatch(fetchAdminUsers());
			return dispatch(updateAdminUserSuccess(result));
		},
		onError: error => dispatch(updateAdminUserError(error)),
	});
};

export const getAdminUserRequest = () => ({
	type: GET_ADMIN_USER_REQUEST,
	payload: {
		user: null,
		isLoadingUser: true,
		hasError: false,
		error: null,
	}
});

export const getAdminUserSuccess = (data) => ({
	type: GET_ADMIN_USER_SUCCESS,
	payload: {
		isLoadingUser: false,
		user: data,
		hasError: false,
		error: null,
	}
});

export const getAdminUsersRequest = () => ({
	type: GET_ADMIN_USERS_REQUEST,
	payload: {
		data: null,
		isLoading: true,
		hasError: false,
		error: null,
	}
});

export const getAdminUsersSuccess = (data) => ({
	type: GET_ADMIN_USERS_SUCCESS,
	payload: {
		isLoading: false,
		data,
		hasError: false,
		error: null,
	}
});

export const getAdminUsersError = (error) => ({
	type: GET_ADMIN_USERS_ERROR,
	payload: {
		isLoading: false,
		data: null,
		hasError: true,
		error: error,
	}
});

export const updateAdminUserRequest = () => ({
	type: UPDATE_ADMIN_USER_REQUEST,
	payload: {
		isUpdating: true,
		hasError: false,
		error: null,
	}
});

export const updateAdminUserSuccess = (user) => ({
	type: UPDATE_ADMIN_USER_SUCCESS,
	payload: {
		isUpdating: false,
		user,
		hasError: false,
		error: null,
	}
});

export const updateAdminUserError = (error) => ({
	type: UPDATE_ADMIN_USER_ERROR,
	payload: {
		isUpdating: false,
		hasError: true,
		error: error,
	}
});

const adminUsersReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ADMIN_USERS_REQUEST:
		case GET_ADMIN_USERS_SUCCESS:
		case GET_ADMIN_USERS_ERROR:
		case GET_ADMIN_USER_REQUEST:
		case GET_ADMIN_USER_SUCCESS:
		case UPDATE_ADMIN_USER_REQUEST:
		case UPDATE_ADMIN_USER_SUCCESS:
		case UPDATE_ADMIN_USER_ERROR:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default adminUsersReducer;
