// import { useDispatch, useSelector } from 'react-redux';

// import { addItemToCart } from 'reducers/cartReducer';

// import { openModal } from 'reducers/modalReducer';
// import { MODAL_TYPES } from 'view/ModalManager';
// import Spinner from 'components/Spinner';
// import OutboundLink from 'components/OutboundLink';
import OverviewCard from 'components/OverviewCard';
import PropertyDocumentsList from 'pages/overview/PropertyDocumentsList';

// function getCertificates(property) {
// 	//copy-paste from old violerts.com vue app
// 	return {
// 		"I-Card": { link: "https://hpdonline.hpdnyc.org/HPDonline/provide_address.aspx?p1="+ property?.borough +"&p2="+ property?.house_number +"&p3=" + property?.street_name },
// 		"Certificate of Occupancy": { link: "http://a810-bisweb.nyc.gov/bisweb/COsByLocationServlet?requestid=0&allbin=" + property?.bin },
// 		// "Landmark Designation Report": { prompt: true },
// 		"Landmark Designation Report": { link: 'https://www1.nyc.gov/site/lpc/designations/designation-reports.page' },
// 		"Letter of No Objection": { prompt: true },
// 		"Temporary Use Permit": { prompt: true },
// 		"Building Card": { prompt: true },
// 		"Sanborn Map": { prompt: true },
// 		"Historical Images": { prompt: true },
// 		"Surveys": { prompt: true },
// 		"Asbestos Reports": { prompt: true },
// 		"Equipment Use Permits": { prompt: true },
// 		"FDNY Permits (Annually Paid)": { prompt: true },
// 		// "Public Assembly": { prompt: true },
// 		"Property Card": { prompt: true },
// 		"Docket Book": { prompt: true },
// 		"Title Report": { prompt: true },
// 		"Block / Lot Folder": { prompt: true },
// 		"Public Assembly (Permit + Certificate)": { prompt: true },
// 		// "Tax Map": { comingSoon: true }
// 	};
// };

// function CertificateItem(props) {
// 	const dispatch = useDispatch();

// 	// const openRequestDocumentModal = (documentName) => {
// 	// 	return dispatch(openModal(MODAL_TYPES.DOCUMENT_REQUEST, { documentName }));
// 	// };

// 	const openAddToCartModal = (documentName) => {
// 		return dispatch(openModal(MODAL_TYPES.ADD_TO_CART, { documentName }));
// 	};

// 	if (props.isLandingPage) {
// 		return (
// 			<div>
// 				<span className="link-disabled">{props.certificateName}</span>
// 			</div>
// 		);
// 	} else if (props.link) {
// 		return (
// 			<div>
// 				<OutboundLink url={props.link} label={props.certificateName} />
// 			</div>
// 		);
// 	} else if (props.prompt) {
// 		return (
// 			<button className="btn-hyperlink" onClick={() => openAddToCartModal(props.certificateName, props.address)}>
// 				{props.certificateName}
// 			</button>
// 		);

// 		// return (
// 		// 	<button className="btn-hyperlink" onClick={() => openRequestDocumentModal(props.certificateName)}>
// 		// 		{props.certificateName}
// 		// 	</button>
// 		// );
// 	}
// 	return null;
// }s

function PropertyDocRequestCard(props) {
	const { isLandingPage } = props;
	return (
		<OverviewCard title="Document Requests">
			<PropertyDocumentsList areLinksDisabled={isLandingPage} isCompact />
		</OverviewCard>
	);
}

export default PropertyDocRequestCard;
