import { get } from 'data/services/apiService';

const GET_REAL_PROPERTY_MASTER_DATA_REQUEST = 'GET_REAL_PROPERTY_MASTER_DATA_REQUEST';
const GET_REAL_PROPERTY_MASTER_DATA_SUCCESS = 'GET_REAL_PROPERTY_MASTER_DATA_SUCCESS';
const GET_REAL_PROPERTY_MASTER_DATA_ERROR = 'GET_REAL_PROPERTY_MASTER_DATA_ERROR';

const initialState = {
	propertyId: null,
	documentId: null,
	data: null,
	isLoading: false,
	hasError: false,
	error: null,
};

export const fetchRealPropertyMasterData = (propertyId, documentId) => (dispatch) => {
	let url = `https://api.violerts.com/api/property/${propertyId}/open-data/acris-rpms?filter=${documentId}`;
	dispatch(realPropertyMasterDataRequest(propertyId, documentId));
	return get({
		dispatch,
		url,
		onSuccess: (result) => {
			dispatch(realPropertyMasterDataRequestSuccess(result))
		},
		onError: error => dispatch(realPropertyMasterDataRequestError(error)),
	});
};

export const realPropertyMasterDataRequest = (propertyId, documentId) => ({
	type: GET_REAL_PROPERTY_MASTER_DATA_REQUEST,
	payload: {
		propertyId,
		documentId,
		isLoading: true,
		data: null,
	}
});

export const realPropertyMasterDataRequestSuccess = (data) => ({
	type: GET_REAL_PROPERTY_MASTER_DATA_SUCCESS,
	payload: {
		isLoading: false,
		hasError: false,
		data,
		error: null,
	}
});

export const realPropertyMasterDataRequestError = (error) => ({
	type: GET_REAL_PROPERTY_MASTER_DATA_ERROR,
	payload: {
		isLoading: false,
		data: null,
		hasError: true,
		error: error,
	}
});

const acrisRealPropertyMasterReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_REAL_PROPERTY_MASTER_DATA_REQUEST:
		case GET_REAL_PROPERTY_MASTER_DATA_SUCCESS:
		case GET_REAL_PROPERTY_MASTER_DATA_ERROR:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default acrisRealPropertyMasterReducer;
