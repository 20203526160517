function OutboundLink({ url, label, noIcon, iconOnly, children, className }) {
	if (!url) {
		return null;
	}

	let labelText = label || url;
	let labelElement = <span>{labelText} <i className="bi bi-box-arrow-up-right" /></span>;

	if (noIcon) {
		labelElement = <span>{labelText}</span>;
	} else if (iconOnly) {
		labelElement = <span><i className="bi bi-box-arrow-up-right" /></span>;
	}

	return (
		<a
			href={url}
			target="_blank"
			rel="noreferrer"
			className={className ? className : ''}
		>
			{children || labelElement}
		</a>
	);
}

export default OutboundLink;
