import { useDispatch } from 'react-redux';
import { downloadFile, /*previewFile,*/ deleteFile/*, viewFile*/ } from 'reducers/fileReducer';

import { openModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';

import DateFormatter from 'formatters/DateFormatter';

const FileTableHeader = ({ parentType }) => {
	let header = (
		<tr>
			<th>Filename</th>
			<th>Created At</th>
			{parentType !== 'datasetItem' && <th>Property</th>}
			<th></th>
		</tr>
	);

	if (parentType === 'admin') {
		header = (
			<tr>
				<th>Filename</th>
				<th>Created At</th>
				<th>Property</th>
				<th>User</th>
				<th>Email</th>
				<th></th>
			</tr>
		);
	}
	return header;
}

// const fileUrl = (fileId) => `https://api.violerts.com/api/files/${fileId}/view`;

const FileTableBody = ({ parentType, files, onDownload, onPreview, confirmDelete }) => {
	// const dispatch = useDispatch();
	return files.map((row, i) => (
		<tr key={`${row.name}-${i}`}>
			<td>{row.filename|| '-'}</td>
			<td><DateFormatter text={row.created_at} /></td>
			{/*<td>{row.created_at || '-'}</td>*/}
			{parentType !== 'datasetItem' && <td>{row.properties[0]?.address || '-'}</td>}
			{parentType !== 'admin' && (
				<td>
					<div className="d-flex justify-content-end">
						<button className="btn btn-primary btn-sm me-3" onClick={() => onPreview(row.id, row.filename)}><i className="bi bi-eye" /></button>
						<button className="btn btn-primary btn-sm me-3" onClick={() => onDownload(row.id, row.filename)}><i className="bi bi-cloud-arrow-down" /></button>
						<button className="btn btn-danger btn-sm me-3" onClick={() => confirmDelete(row.id, row.filename)}><i className="bi bi-trash" /></button>
					</div>
				</td>
			)}
			{parentType === 'admin' && ([
				<td key={`${i}-user-name`}>{row.users[0]?.name || '-'}</td>,
				<td key={`${i}-user-email`}>{row.users[0]?.email || '-'}</td>,
				<td key={`${i}-buttons`}>
					<div className="d-flex justify-content-end">
						<button className="btn btn-primary btn-sm me-3" onClick={() => onPreview(row.id, row.filename)}><i className="bi bi-eye" /></button>
						<button className="btn btn-primary btn-sm me-3" onClick={() => onDownload(row.id, row.filename)}><i className="bi bi-cloud-arrow-down" /></button>
					</div>
				</td>,
			])}
		</tr>
	));
}

const FileTable = ({ files, parentType, token }) => {
	const dispatch = useDispatch();
	const onDownload = (fileId, filename) => {
		dispatch(downloadFile(fileId, filename));
	}
	const onPreview = (fileId) => {
		window.open(`https://api.violerts.com/api/files/${fileId}/view?token=${token}`, '_blank');
		// dispatch(previewFile(fileId));
	}

	const confirmDelete = (fileId, filename) => {
		return dispatch(openModal(MODAL_TYPES.CONFIRM, { 
			color: 'danger',
			title: 'Confirm Deletion',
			onConfirm: () => dispatch(deleteFile(fileId)),
			children: (<div>Are you sure you want to delete <strong>{filename}</strong>?</div>),
			confirmLabel: 'Delete',
		}));
	};

	return (
		<table className="table table-striped data-table">
			<thead>
				<FileTableHeader parentType={parentType} />
			</thead>
			<tbody>
				{(!files || files.length === 0) && <tr><td colSpan={4} className="text-center">No documents found</td></tr>}
				<FileTableBody parentType={parentType} files={files} onDownload={onDownload} onPreview={onPreview} confirmDelete={confirmDelete} />
			</tbody>
		</table>
	);
};

export default FileTable;
