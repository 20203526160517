import { throttle } from 'lodash';

import { GET_PROPERTY_SUCCESS } from 'reducers/propertyReducer';
import { fetchFirstLoadTasks } from 'reducers/firstLoadTasksReducer';

import { actions } from 'data/store'

import { subscribe, unsubscribe } from 'data/services/pusherService';
import { getAppRoute, getReducerName } from 'data/dataSourceConstants';
import { silentlyRefetchProperty } from 'data/reducers/propertyReducer';

import { dataConfig } from 'data/dataConfig';

let pusherDataSetLookup = null;

const buildPageByPusherDataSet = () => {
	let lookup = {};

	Object.keys(dataConfig).forEach((dataSetName) => {
		let dataSetConfig = dataConfig[dataSetName];

		dataSetConfig.dataSources.forEach((dataSource) => {
			if (dataSetConfig.pusherDataSetNames[dataSource]) {
				lookup[dataSetConfig.pusherDataSetNames[dataSource]] = {
					routeName: getAppRoute(dataSetName, dataSource),
					reducerName: getReducerName(dataConfig[dataSetName], dataSource)
				};
			}
		});
	})
	return lookup;
}

const throttledSilentlyRefetchProperty = throttle((dispatch) => {
	dispatch(silentlyRefetchProperty());
}, 3000, { trailing: true });


const updateData = (pusherDataSet, getState, dispatch) => {
	let state = getState();
	let path = state.router.location.pathname;
	let propertyId = state.property.propertyData.id;

	// dispatch(silentlyRefetchProperty());
	throttledSilentlyRefetchProperty(dispatch);

	if (pusherDataSetLookup === null) {
		pusherDataSetLookup = buildPageByPusherDataSet();
	}

	let { routeName, reducerName } = pusherDataSetLookup[pusherDataSet] || {};
	let pathParts = path.split('/');
	if (routeName && reducerName && pathParts[1] === 'property' && pathParts[3] === routeName) {
		dispatch(actions[reducerName].silentRefresh(propertyId));
	}
}


//separate throttled function for each data set, to ensure no dataset gets skipped
const updatersByDataset = {};
const getThrottledUpdater = (datasetName) => {
	if (!updatersByDataset[datasetName]) {
		const updater = throttle((datasetName, getState, dispatch) => {
			// console.log('throttled update', datasetName);
			updateData(datasetName, getState, dispatch);
		}, 2000, { trailing: true });
		updatersByDataset[datasetName] = updater;
	}
	return updatersByDataset[datasetName];
}

const throttledFetchFirstLoadTasks = throttle((propertyId, dispatch) => {
	dispatch(fetchFirstLoadTasks(propertyId));
}, 3000, { trailing: true });

// let seen = {};


const pusherMiddleware = ({ getState, dispatch }) => {
	return next => action => {
		if (action.type === GET_PROPERTY_SUCCESS) {
			let currentState = getState();
			let previousPropertyId = currentState.property.propertyData?.id;
			let nextPropertyId = action.payload.propertyData?.id;
			if (previousPropertyId) {
				unsubscribe(previousPropertyId);
			}

			if (nextPropertyId) {
				subscribe({
					propertyId: nextPropertyId,
					onUpdate: (data) => {
						// if (!seen[data.datasetName]) {
							// seen[data.datasetName] = true;	
							// console.log('onUpdate', data);
						// }
						let throttledUpdater = getThrottledUpdater(data.datasetName);
						throttledUpdater(data.datasetName, getState, dispatch);

						// updateData(data.datasetName, getState, dispatch);
						// throttledUpdateDataset(data.datasetName, getState, dispatch);
					},
					onFirstLoadTaskComplete: (data) => {
						// console.log('onFirstLoadTaskComplete', data);
						// dispatch(fetchFirstLoadTasks(nextPropertyId));
						throttledFetchFirstLoadTasks(nextPropertyId, dispatch);
					},
				});
			}
		}
		return next(action);
	}
}

export default pusherMiddleware;
