
export const numberWithCommas = (x) => {
	return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

//todo: dont use array index

export const getPenalties = (totals) => {
	let ecbOpenDataPenalties = totals?.[0]?.totalPenalties;
	let ecbBISPenalties = totals?.[1]?.totalPenalties;

	let penalties = {
		ecb: Math.max(ecbOpenDataPenalties, ecbBISPenalties),
		ecbOpenData: ecbOpenDataPenalties,
		ecbBis: ecbBISPenalties,
		citypay: totals?.[2]?.totalPenalties,
		total: totals?.[3]?.totalPenalties,
	};

	return penalties;
};

export const getRefunds = (totals) => {
	let ecbOpenDataRefunds = totals?.[0]?.totalRefunds;
	let ecbBISRefunds = totals?.[1]?.totalRefunds;

	let refunds = {
		ecb: Math.max(ecbOpenDataRefunds, ecbBISRefunds),
		ecbOpenData: ecbOpenDataRefunds,
		ecbBis: ecbBISRefunds,
		citypay: totals?.[2]?.totalRefunds,
		total: totals?.[3]?.totalRefunds,
	};

	return refunds;
};
