import { push } from 'connected-react-router';
import { get, post } from 'data/services/apiService';
import { getHouseNumber, getStreetName, getBoroughNum, getAddressWithoutUSA } from 'utils/googlePlaceUtils';
import { fetchProperty, postNewProperty } from 'reducers/propertyReducer';

const POST_ADDRESS_REQUEST = 'POST_ADDRESS_REQUEST';
const POST_ADDRESS_SUCCESS = 'POST_ADDRESS_SUCCESS';
const POST_ADDRESS_ERROR = 'POST_ADDRESS_ERROR';

const initialState = {
	address: null,
	isLoading: false,
	error: null,
};

function makeAwsRequest(dispatch, googlePlace, blockAndLot) {
	let awsBaseUrl = 'https://itnqkr2qjj.execute-api.us-east-1.amazonaws.com/dev/overview';
	let url;
	let address;

	if (googlePlace) {
		let houseNum = encodeURIComponent(getHouseNumber(googlePlace));
		let streetName = encodeURIComponent(getStreetName(googlePlace));
		let boroughNum = getBoroughNum(googlePlace);
		address = getAddressWithoutUSA(googlePlace);
		url = `${awsBaseUrl}?house_number=${houseNum}&street=${streetName}&borough=${boroughNum}`;
	} else if (blockAndLot) {
		url = `${awsBaseUrl}?block=${blockAndLot.block}&lot=${blockAndLot.lot}&borough=${blockAndLot.borough}`;
	} else {
		return dispatch(addressRequestError({ message: 'Error: Google place or block, lot and borough are required' }));
	}

	return get({
		dispatch,
		url,
		onSuccess: (result) => {
			if (!result.hasOwnProperty("house_number")) {
				//this is when the aws process attempts to scrape a property that doesn't exist (or is outside nyc) 
				dispatch(push(`/property-not-found`));
				return dispatch(addressRequestError({ message: "Error: That property doesn't seem to exist" }));
			}
			dispatch(addressRequestSuccess());
			return dispatch(postNewProperty(result, address));
		},
		onError: (error) => {
			dispatch(addressRequestError(error));
			dispatch(push(`/property-not-found`));
		},
	});
}


export const checkAddressAsString = (address) => (dispatch) => {
	let url = `https://api.violerts.com/api/check/property`;

	dispatch(addressRequest(address));

	return post({
		dispatch,
		url,
		data: { address: address },
		onSuccess: (result) => {
			if (result && result.id) {
				dispatch(addressRequestSuccess());
				return dispatch(fetchProperty(address));
			} else {
				//we can't make an aws request from an address string, since borough num is probably missing
				//or it could just be junk
				dispatch(addressRequestError({ message: "Error: We couldn't find that property in our database" }));
				dispatch(push(`/property-not-found`));
			}
		},
		onError: (error) => {
			dispatch(addressRequestError(error));
			dispatch(push(`/property-not-found`));
		},
	});
};

export const checkAddressByGooglePlace = (googlePlace) => (dispatch) => {
	let url = `https://api.violerts.com/api/check/property`;
	let addressWithoutUSA = getAddressWithoutUSA(googlePlace);

	dispatch(addressRequest(addressWithoutUSA));

	return post({
		dispatch,
		url,
		data: { address: addressWithoutUSA },
		onSuccess: (result) => {
			if (result && result.id) {
				dispatch(addressRequestSuccess());
				return dispatch(fetchProperty(addressWithoutUSA));
			} else {
				return makeAwsRequest(dispatch, googlePlace);
			}
		},
		onError: (error) => {
			dispatch(addressRequestError(error));
			dispatch(push(`/property-not-found`));
		},
	});
};

export const checkBlockAndLot = ({ block, lot, borough }) => (dispatch, getState) => {
	let state = getState();
	let userId = state.user.id || null;

	let url = `https://api.violerts.com/api/check/property/blockandlot?block=${block}&lot=${lot}&user_id=${userId}`;

	dispatch(addressRequest(null)); //todo: handle block and lot or just get rid of address prop

	return get({
		dispatch,
		url,
		onSuccess: (result) => {
			dispatch(addressRequestSuccess());
			return dispatch(fetchProperty(result.address));
		},
		onError: (error) => {
			return makeAwsRequest(dispatch, null, { block, lot, borough });
		},
	});
}

export const addressRequest = (address) => ({
	type: POST_ADDRESS_REQUEST,
	payload: {
		address,
		isLoading: true,
	}
});

export const addressRequestSuccess = () => ({
	type: POST_ADDRESS_SUCCESS,
	payload: {
		isLoading: false,
		error: null,
	}
});

export const addressRequestError = (error) => ({
	type: POST_ADDRESS_ERROR,
	payload: {
		isLoading: false,
		error: error,
	}
});

const addressReducer = (state = initialState, action) => {
	switch (action.type) {
		case POST_ADDRESS_REQUEST:
		case POST_ADDRESS_SUCCESS:
		case POST_ADDRESS_ERROR:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default addressReducer;
