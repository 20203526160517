import { useSelector } from 'react-redux';

import Badge from 'components/Badge';

import { numberWithCommas,getPenalties, getRefunds } from 'utils/amountsDueUtils';

import './AmountsDue.css';

// function numberWithCommas(x) {
// 	return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// }

function AmountBadge({ amount, isRefund }) {
	if (amount === 0) {
		return `$${amount}`;
	} else if (!amount) {
		return `-`;
	} else if (isRefund) {
		return <Badge color="success">${numberWithCommas(Math.abs(amount))}</Badge>;
	}
	return <Badge color="danger">${numberWithCommas(Math.abs(amount))}</Badge>;
}

function AmountsDue(props) {
	const {
		totals,
	} = useSelector(state => ({
		totals: state.property.propertyData?.totals,
	}));

	let penalties = getPenalties(totals);
	let refunds = getRefunds(totals);

	return (
		<div className="amounts-due">
			{/*<div className="amounts-due-header">Penalties and Refunds</div>*/}
			<table className="table">
				<thead>
					<tr>
						{/*<td />*/}
						<td>Penalties and Refunds</td>
						{/*<td>ECB Violations</td>*/}
						<td>ECB Violations (Open Data)</td>
						<td>ECB Violations (BIS)</td>
						<td>CityPay Tickets</td>
						<td>Building Total</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Penalties Due</td>
						{/*<td><AmountBadge amount={penalties.ecb} /></td>*/}
						<td><AmountBadge amount={penalties.ecbOpenData} /></td>
						<td><AmountBadge amount={penalties.ecbBis} /></td>
						<td><AmountBadge amount={penalties.citypay} /></td>
						<td><AmountBadge amount={penalties.total} /></td>
					</tr>
					<tr>
						<td>Refunds Available</td>
						{/*<td><AmountBadge amount={refunds.ecb} isRefund /></td>*/}
						<td><AmountBadge amount={refunds.ecbOpenData} isRefund /></td>
						<td><AmountBadge amount={refunds.ecbBis} isRefund /></td>
						<td><AmountBadge amount={refunds.citypay} isRefund /></td>
						<td><AmountBadge amount={refunds.total} isRefund /></td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}

export default AmountsDue;
