import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import { signOut } from 'reducers/userReducer';
import { isAdminUser } from 'utils/subscriptionPlans';
import Dropdown from 'components/Dropdown';
import { toggleDarkMode } from 'data/services/darkMode';

import "./AccountDropdown.css";

const AccountDropdown = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const {
		isLoggedIn,
		isAdmin,
	} = useSelector(state => ({
		isLoggedIn: !!state.user.userData?.id,
		isAdmin: isAdminUser(state.user.userData),
	}));


	const signedOutMenuItems = [
		{ text: 'Sign In', onClick: () => history.push('/sign-in') },
		{ text: 'Sign Up', onClick: () => history.push('/sign-up') },
		{ text: 'Dark Mode', onClick: () => toggleDarkMode() },
	];

	const dashboardMenuItem = [
		{ text: 'Dashboard', onClick: () => history.push('/dashboard'), icon: 'bi-clipboard-data' },
	];

	const adminMenuItem = [
		{ text: 'Admin', onClick: () => history.push('/admin'), icon: 'bi-people' },
		// { text: 'File Admin', onClick: () => history.push('/file-admin'), icon: 'bi-file-earmark-lock' },
	];

	const userMenuItems = [
		{ text: 'User Settings', onClick: () => history.push('/account-settings'), icon: 'bi-gear' },
		{ text: 'Dark Mode', icon: 'bi-moon', onClick: () => toggleDarkMode() },
		{
			text: 'Sign Out',
			icon: 'bi-box-arrow-right',
			onClick: () => {
				dispatch(signOut());
				history.push('/sign-in');
			},
		},
	];

	let signedInMenuItems = [].concat(dashboardMenuItem);
	if (isAdmin) {
		signedInMenuItems = signedInMenuItems.concat(adminMenuItem);
	}
	signedInMenuItems = signedInMenuItems.concat(userMenuItems);

	return (
		<div className="account-dropdown">
			<Dropdown
				buttonIcon="bi-person-circle"
				hideChevron
				menuItems={isLoggedIn ? signedInMenuItems : signedOutMenuItems}
			/>
		</div>
	);
};

export default AccountDropdown;
