import { Route } from "react-router-dom";

import AppContainer from 'containers/AppContainer';
import PropertyContainer from 'containers/PropertyContainer';

import PropertyOverview from 'pages/PropertyOverview';
import PropertyCertificates from 'pages/PropertyCertificates';
import Tasks from 'pages/Tasks';
import CalendarPage from 'pages/CalendarPage';
import ExternalLinksPage from 'pages/ExternalLinksPage';
import ComplianceDates from 'pages/ComplianceDates';
import Representatives from 'pages/Owners';
import Notifications from 'pages/Notifications';
import MobilePropertyNavPage from 'pages/mobile/MobilePropertyNavPage';

const routes = {
	"/property/:address/compliance-dates": ComplianceDates,
	"/property/:address/calendar": CalendarPage,
	"/property/:address/external-links": ExternalLinksPage,
	"/property/:address/property-documents": PropertyCertificates,
	"/property/:address/tasks": Tasks,
	"/property/:address/representatives": Representatives,
	"/property/:address/notifications": Notifications,
	"/property/:address/nav": MobilePropertyNavPage,
	"/property/:address": PropertyOverview,
};

function PropertyRoutes() {
	return Object.keys(routes).map((path) => {
		const Component = routes[path];

		return (
			<Route key={path} path={path}>
				<AppContainer>
					<PropertyContainer>
						<Component />
					</PropertyContainer>
				</AppContainer>
			</Route>
		);
	});
}

export default PropertyRoutes;
