import { Link } from "react-router-dom";
import AddressSearch from 'components/addressSearch/AddressSearch';
import { usePageTitle } from 'utils/pageTitle';
import Logo from 'components/appHeader/Logo';

import './PropertySearch.css';


const PropertySearch = (props) => {
	usePageTitle();
	return (
		<div className="property-search-page">
			<div className="property-search-page-content">

				{/*<img src="/violerts-logo.png" className="logo" alt="violerts" />*/}
				<Logo />
				<h1>Property compliance <br />just got easier</h1>

				<div className="address-search-wrapper">
					<AddressSearch
						addressSearchLabel="Find an address"
						hideLabel
					/>
				</div>

				<div className="contact-info">
					<div><i className="bi bi-telephone-fill" /> +1-212-401-6011</div>
					<div><i className="bi bi-envelope-fill" /> admin@violerts.com</div>
					<Link to="/use-policy">Acceptable Use Policy</Link>
				</div>
			</div>
		</div>
	);	
}

export default PropertySearch;
