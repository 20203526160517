import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useLocation } from "react-router-dom";

import { getDistrictUrl } from 'utils/zoningDistrictUtils';
import { getBuildingClassification } from "utils/buildingClassifications";
import { fetchMostRecentOwner } from 'reducers/ownersReducer';
import OutboundLink from 'components/OutboundLink';
import Spinner from 'components/Spinner';
import Tooltip from 'components/Tooltip';
import AcrisLink from 'pages/overview/AcrisLink';
import { openModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';

import { useMediaPredicate } from 'react-media-hook';
import { TABLET_SIZE, MOBILE_SIZE } from 'utils/mediaQueryUtils';

import './BuildingInfo.css';

function BuildingInfo(props) {
	const dispatch = useDispatch();
	const { propertyId } = props;
	let { address } = useParams();
	const location = useLocation();
	const isLandingPage = location.pathname === '/';
	const {
		isLoading,
		zoningData,
		property,
	} = useSelector(state => ({
		isLoading: state.zoning.isLoading || state.property.isLoading,
		zoningData: state.zoning.data || {},
		property: state.property.propertyData,
	}));
	const isTablet = useMediaPredicate(TABLET_SIZE);
	const isMobile = useMediaPredicate(MOBILE_SIZE);

	useEffect(() => {
		if (propertyId){
			dispatch(fetchMostRecentOwner(propertyId));	
		}
	}, [propertyId, dispatch]);

	//todo: refactor this to use everywhere we use ` || '-'`
	const display = (value) => {
		if (value === null || value === undefined) {
			return '-';
		}
		return value;
	}

	const displayLotSize = (zoningData) => {
		if (!zoningData?.lotfront || !zoningData?.lotdepth) {
			return '-';
		}
		return `${zoningData.lotfront} x ${zoningData.lotdepth}`
	}

	let representativeSection = (
		<div className="building-info-top building-info-section">
			{(isMobile && property?.thumbnail_url) && (
				<div className="property-image-wrapper">
					<img className="property-image" src={`https://api.violerts.com${property?.thumbnail_url}`} alt="Building thumbnail" />
				</div>
			)}
			<div className="representative-section">
				<div className="rep-name-wrapper">
					<div className="representative-name">{display(zoningData.ownername)}</div>
					<div className="rep-icons">
						{/*<OutboundLink url="https://apps.dos.ny.gov/publicInquiry/#search">
							<img src="/dos-ny-32.png"  alt="NY Department of State" />
						</OutboundLink>*/}
						<Link to={`/property/${property?.address}/dosBusinessEntitiesOpenData`}>
							<img src="/dos-ny-32.png"  alt="NY Department of State" />
						</Link>
						<OutboundLink url={`https://www.google.com/search?q=${zoningData.ownername?.replace(' ', '+')}`}>
							<img src="/google-favicon.ico"  alt="Google" />
						</OutboundLink>
						<OutboundLink url="https://a836-pts-access.nyc.gov/care/search/commonsearch.aspx?mode=address">
							<img className="img-dof" src="/nyc_finance_logo.png"  alt="NYC Department of Finance" />
						</OutboundLink>
						<AcrisLink>
							Acris
							{/*<img className="img-dof" src="/nyc_finance_logo.png"  alt="NYC Department of Finance" />*/}
						</AcrisLink>
						<span className="view-all-reps">{isLandingPage ? <span className="link-disabled">View all representatives</span> :
							<Link to={`/property/${address}/representatives`}>View all representatives</Link>
						}</span>
					</div>
				</div>

			</div>
		</div>
	);

	let buildingInfoTable = (
		<div>
			<div className="building-general-info building-info-section">

				<div className="building-info-column">
					<div className="building-info-item">
						<span className="building-info-field">Year Built</span>
						<span className="building-info-value">{display(zoningData.yearbuilt)}</span>
					</div>
					<div className="building-info-item">
						<span className="building-info-field">Block/Lot</span>
						<span className="building-info-value">{display(zoningData.block)}/{display(zoningData.lot)}</span>
					</div>
				</div>

				<div className="building-info-column">
					<div className="building-info-item">
						<span className="building-info-field">Lot Area</span>
						<span className="building-info-value">{display(zoningData?.lotarea?.toLocaleString())}</span>
					</div>
					<div className="building-info-item">
						<span className="building-info-field"># of Buildings</span>
						<span className="building-info-value">{display(zoningData.numbldgs)}</span>
					</div>
				</div>

				<div className="building-info-column">
					<div className="building-info-item">
						<span className="building-info-field"># of Stories</span>
						<span className="building-info-value">{display(zoningData.numfloors)}</span>
					</div>
					<div className="building-info-item">
						<span className="building-info-field"># of Units</span>
						<span className="building-info-value">{display(zoningData.unitstotal)}</span>
					</div>
				</div>

				<div className="building-info-column">
					<div className="building-info-item">
						<span className="building-info-field">Gross Floor Area</span>
						<span className="building-info-value">{display(zoningData?.bldgarea?.toLocaleString())}</span>
					</div>
					<div className="building-info-item">
						<span className="building-info-field">
							<OutboundLink url="https://www1.nyc.gov/site/lpc/designations/designation-reports.page">Landmark</OutboundLink>
						</span>
						<span className="building-info-value">{display(zoningData.landmark)}</span>
					</div>
				</div>

				<div className="building-info-column">
					<div className="building-info-item">
						<span className="building-info-field">Lot Size</span>
						<span className="building-info-value">{displayLotSize(zoningData)}</span>
					</div>
					<Tooltip text={`${getBuildingClassification(zoningData.bldgclass)}`}>
						<div className="building-info-item">
							<span className="building-info-field">Building Class</span>
							<span className="building-info-value">
								<OutboundLink url={`https://www.nyc.gov/assets/finance/jump/hlpbldgcode.html#${zoningData.bldgclass?.[0]}`}>{display(zoningData.bldgclass)}</OutboundLink>
							</span>
						</div>
					</Tooltip>
				</div>
					
				<div className="building-info-column">
					<div className="building-info-item">
						<span className="building-info-field">SRO Restricted</span>
						<span className="building-info-value">{display(property?.sro_restricted)}</span>
					</div>
					<div className="building-info-item">
						<span className="building-info-field">OER</span>
						<span className="building-info-value">{display(property?.environmental_restrictions)}</span>
					</div>
				</div>

			</div>
		</div>
	);

	let zoningMapElement = display(zoningData.zonemap);
	if (property?.zoning_map_number) {
		let mapNum = property.zoning_map_number.toLowerCase();
		let mapUrl = `https://www1.nyc.gov/assets/planning/download/pdf/zoning/zoning-maps/map${mapNum}.pdf`;
		zoningMapElement = (
			<OutboundLink url={mapUrl}>
				{display(zoningData.zonemap)}
			</OutboundLink>
		)
	}

	let openSpecialDistModal = () => {
		console.log('hi')
		dispatch(openModal(MODAL_TYPES.SPECIAL_DISTRICTS))
	};
	
	let zoningTable = (
		<div className="building-zoning-info building-info-section">
			<div className="building-info-column">
				<div className="building-info-item">
					<span className="building-info-field">Built FAR</span>
					<span className="building-info-value">{display(zoningData.builtfar)}</span>
				</div>
				<div className="building-info-item">
					<span className="building-info-field">Commercial FAR</span>
					<span className="building-info-value">{display(zoningData.commfar)}</span>
				</div>
			</div>
			<div className="building-info-column">
				<div className="building-info-item">
					<span className="building-info-field">Residential FAR</span>
					<span className="building-info-value">{display(zoningData.residfar)}</span>
				</div>
				<div className="building-info-item">
					<span className="building-info-field">Facility FAR</span>
					<span className="building-info-value">{display(zoningData.sro_restricted)}</span>
				</div>
			</div>
			<div className="building-info-column">
				<div className="building-info-item">
					<span className="building-info-field">Zoning district 1/2</span>
					<span className="building-info-value">
						<OutboundLink url={getDistrictUrl(zoningData.zonedist1)}>{display(zoningData.zonedist1)}</OutboundLink>/
						<OutboundLink url={getDistrictUrl(zoningData.zonedist2)}>{display(zoningData.zonedist2)}</OutboundLink>
					</span>
				</div>
				<div className="building-info-item">
					<span className="building-info-field">Zoning district 3/4</span>
					<span className="building-info-value">
						<OutboundLink url={getDistrictUrl(zoningData.zonedist3)}>{display(zoningData.zonedist3)}</OutboundLink>/
						<OutboundLink url={getDistrictUrl(zoningData.zonedist4)}>{display(zoningData.zonedist4)}</OutboundLink>
					</span>
				</div>
			</div>
			<div className="building-info-column">
				<div className="building-info-item">
					<span className="building-info-field">
						<OutboundLink url={'https://www.nyc.gov/site/planning/zoning/districts-tools/c1-c2-overlays.page'}>Commercial Overlay 1</OutboundLink>
					</span>
					<span className="building-info-value">{display(zoningData.overlay1)}</span>
				</div>
				<div className="building-info-item">
					<span className="building-info-field">
						<OutboundLink url={'https://www.nyc.gov/site/planning/zoning/districts-tools/c1-c2-overlays.page'}>Commercial Overlay 2</OutboundLink>
					</span>
					<span className="building-info-value">{display(zoningData.overlay2)}</span>
				</div>
			</div>
			<div className="building-info-column">
				<div className="building-info-item">
					<span className="building-info-field">
						<button onClick={openSpecialDistModal} className="btn-hyperlink btn btn-sm">Special District 1/2</button>
					</span>
					<span className="building-info-value">{display(zoningData.spdist1)}/{display(zoningData.spdist2)}</span>
				</div>
				<div className="building-info-item">
					<span className="building-info-field">
						<button onClick={openSpecialDistModal} className="btn-hyperlink btn btn-sm">Special District 3</button>
					</span>
					<span className="building-info-value">{display(zoningData.spdist3)}</span>
				</div>
			</div>
			<div className="building-info-column">
				<div className="building-info-item">
					<span className="building-info-field">Zoning Map</span>
					<span className="building-info-value">{zoningMapElement}</span>
				</div>
				<div className="building-info-item">
					<span className="building-info-field">Land Use</span>
					<span className="building-info-value">{display(zoningData.landuse)}</span>
				</div>
			</div>
		</div>
	);

	return (
		<div>
			{isLoading && <Spinner />}
			<div className="building-info">
				{/*<h2>Property Representative</h2>*/}
				{representativeSection}
			
				<div className={`info-sections-wrapper ${isTablet ? 'd-flex' : ''}`}>
					<div>
						<h2>Building Info</h2>
						{buildingInfoTable}
					</div>
					<div>
						<h2>Zoning Info</h2>
						{zoningTable}
					</div>
				</div>

			</div>
		</div>
	);
}

export default BuildingInfo;
