// import { Link } from "react-router-dom";
import { useEffect } from 'react';
import {
	useSelector,
	useDispatch
} from 'react-redux';

import { fetchAdminUsers } from 'reducers/adminUsersReducer';
import Spinner from 'components/Spinner';
import Paginator from 'components/Paginator';
import { openModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';


const AdminUserManagementCard = (props) => {
	const dispatch = useDispatch();
	let {
		users,
		isLoading,
		currentPage,
		totalPages,
	} = useSelector(state => ({
		users: state.adminUsers.data?.data || [],
		isLoading: state.adminUsers.isLoading,
		currentPage: state.adminUsers.data?.current_page,
		totalPages: state.adminUsers.data?.last_page,
	}));

	useEffect(() => {
		dispatch(fetchAdminUsers());
	}, [dispatch]);

	const openUserAdminModal = (userId) => {
		dispatch(openModal(MODAL_TYPES.USER_ADMIN, { userId }));
	}

	return (
		<div className="card">
			<div className="card-header">
				User Management
			</div>
			<div className="card-body">
				{isLoading && <Spinner />}
				<div className="dashboard-table-wrapper">
					<table className="table table-striped">
						<thead><tr>
							<th />
							<th>Email</th>
							<th>Name</th>
							<th>Created At</th>
						</tr></thead>
						<tbody>
							{users?.map((item) => (
								<tr key={item.id}>
									<td>
										<button className="btn btn-hyperlink" onClick={() => openUserAdminModal(item.id)}>
											<i className="bi bi-pencil-square" />
										</button>
									</td>
									<td>{item.email || '-'}</td>
									<td className="dashboard-username-cell">{item.name || '-'}</td>
									<td className="dashboard-date-time-cell">{item.created_at || '-'}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<Paginator
					currentPage={currentPage}
					totalPages={totalPages}
					setPage={(pageNum) => dispatch(fetchAdminUsers('created_at|desc', pageNum, 10))}
				/>
			</div>
		</div>

	);
}

export default AdminUserManagementCard;
