import { get, download, upload, del } from 'data/services/apiService';
import { dataConfig } from 'data/dataConfig';

const GET_FILES_REQUEST = 'GET_FILES_REQUEST';

// const GET_USER_FILES_REQUEST = 'GET_USER_FILES_REQUEST';
const GET_USER_FILES_SUCCESS = 'GET_USER_FILES_SUCCESS';
const GET_USER_FILES_ERROR = 'GET_USER_FILES_ERROR';

const GET_ADMIN_FILES_SUCCESS = 'GET_ADMIN_FILES_SUCCESS';
const GET_ADMIN_FILES_ERROR = 'GET_ADMIN_FILES_ERROR';

const DELETE_FILE_REQUEST = 'DELETE_FILE_REQUEST';
const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
const DELETE_FILE_ERROR = 'DELETE_FILE_ERROR';

// const GET_PROPERTY_FILES_REQUEST = 'GET_PROPERTY_FILES_REQUEST';
const GET_PROPERTY_FILES_SUCCESS = 'GET_PROPERTY_FILES_SUCCESS';
const GET_PROPERTY_FILES_ERROR = 'GET_PROPERTY_FILES_ERROR';

const GET_DATASET_ITEM_FILES_SUCCESS = 'GET_DATASET_ITEM_FILES_SUCCESS';
const GET_DATASET_ITEM_FILES_ERROR = 'GET_DATASET_ITEM_FILES_ERROR';
const CLEAR_DATASET_ITEM_FILES = 'CLEAR_DATASET_ITEM_FILES';

const POST_FILE_REQUEST = 'POST_FILE_REQUEST';
const POST_FILE_SUCCESS = 'POST_FILE_SUCCESS';
const POST_FILE_ERROR = 'POST_FILE_ERROR';

const initialState = {
	userFiles: null,
	propertyFiles: {},
	datasetItemFiles: null,
	adminFiles: null,
	isLoading: false,
	isUploading: false,
	hasError: false,
	error: null,
};

//this isn't usable right now, need to open in a new tab, but can't send bearer token if we do that
// export const viewFile = (fileId) => (dispatch, getState) => {
// 	let url = `https://api.violerts.com/api/files/${fileId}/view`;
// 	let state = getState();
// 	return get({
// 		dispatch,
// 		url,
// 		authToken: state.user.authToken,
// 		onSuccess: (res) => {
// 			console.log('res', res)
// 			// dispatch(deleteFileSuccess());
// 			// dispatch(getUserFiles());
// 		},
// 		onError: (error) => {
// 			// dispatch(deleteFileError(error))
// 		},
// 	});
// }

export const deleteFile = (fileId) => (dispatch, getState) => {
	let url = `https://api.violerts.com/api/files/${fileId}`;
	let state = getState();
	dispatch(deleteFileRequest());
	return del({
		dispatch,
		url,
		authToken: state.user.authToken,
		onSuccess: () => {
			dispatch(deleteFileSuccess());
			dispatch(getUserFiles());
		},
		onError: (error) => {
			dispatch(deleteFileError(error))
		},
	});
}

export const downloadFile = (fileId, filename) => (dispatch, getState) => {
	let url = `https://api.violerts.com/api/files/${fileId}/download`;
	let state = getState();
	// dispatch(getFilesRequest());
	return download({
		dispatch,
		filename,
		url,
		authToken: state.user.authToken,
		onSuccess: (result) => {
			console.log('result', result)
			// window.open(result, '_blank');
			// dispatch(getUserFilesSuccess(result));
		},
		onError: (error) => {
			console.log('error', error)
			// dispatch(getUserFilesError(error))
		},
	});
}

export const previewFile = (fileId) => (dispatch, getState) => {
	let state = getState();
	let url = `https://api.violerts.com/api/files/${fileId}/view?token=${state.user.authToken}`;
	// dispatch(getFilesRequest());
	return get({
		dispatch,
		url,
		// authToken: state.user.authToken,
		onSuccess: (result) => {
			console.log('result', result)
			// window.open(result, '_blank');
			// dispatch(getUserFilesSuccess(result));
		},
		onError: (error) => {
			console.log('error', error)
			// dispatch(getUserFilesError(error))
		},
	});
}

export const getUserFiles = (page=1) => (dispatch, getState) => {
	let url = `https://api.violerts.com/api/user/files?page=${page}`;
	let state = getState();
	dispatch(getFilesRequest());
	return get({
		dispatch,
		url,
		authToken: state.user.authToken,
		onSuccess: (result) => {
			dispatch(getUserFilesSuccess(result));
		},
		onError: error => dispatch(getUserFilesError(error)),
	});
};

export const getAdminFiles = (page=1) => (dispatch, getState) => {
	let url = `https://api.violerts.com/api/admin/files?page=${page}`;
	let state = getState();
	dispatch(getFilesRequest());
	return get({
		dispatch,
		url,
		authToken: state.user.authToken,
		onSuccess: (result) => {
			dispatch(getAdminFilesSuccess(result));
		},
		onError: error => dispatch(getAdminFilesError(error)),
	});
};

export const getPropertyFiles = (propertyId, page=1) => (dispatch, getState) => {
	let url = `https://api.violerts.com/api/property/${propertyId}/files?page=${page}`;
	let state = getState();
	dispatch(getFilesRequest());
	return get({
		dispatch,
		url,
		authToken: state.user.authToken,
		onSuccess: (result) => {
			let propertyFiles = state.files.propertyFiles;
			propertyFiles[propertyId] = result;
			dispatch(getPropertyFilesSuccess(propertyFiles));
		},
		onError: error => dispatch(getPropertyFilesError(error)),
	});
};

export const getDatasetItemFiles = (dataSet, dataSource, recordId, page=1) => (dispatch, getState) => {
	let state = getState();
	dispatch(clearDatasetItemFiles());
	if (!dataSet || !dataSource || !recordId) {
		console.log('get dataset item file missing param:', dataSet, dataSource, recordId);
		return;
	}
	let dataSetPath = dataConfig[dataSet]?.fileUploadName[dataSource];
	if (!dataSetPath) {
		console.log('get dataset item file missing dataSetPath:', dataSetPath);
		return;
	}
	let url = `https://api.violerts.com/api/dataset/${dataSetPath}/${recordId}/files?page=${page}`;
	dispatch(getFilesRequest());
	return get({
		dispatch,
		url,
		authToken: state.user.authToken,
		onSuccess: (result) => {
			dispatch(getDatasetItemFilesSuccess(result));
		},
		onError: error => dispatch(getDatasetItemFilesError(error)),
	});
};

export const uploadDataSetItemFile = (dataSet, dataSource, recordId, file) => (dispatch, getState) => {
	if (!dataSet || !dataSource || !recordId) {
		console.log('uploadDataSetItemFile missing param:', dataSet, dataSource, recordId);
		return;
	}
	let dataSetPath = dataConfig[dataSet]?.fileUploadName[dataSource];
	if (!dataSetPath) {
		console.log('uploadDataSetItemFile missing dataSetPath:', dataSetPath);
		return;
	}
	let url = `https://api.violerts.com/api/dataset/${dataSetPath}/${recordId}/files`;
	let state = getState();
	dispatch(uploadFileRequest());
	return upload({
		dispatch,
		url,
		data: file,
		authToken: state.user.authToken,
		contentType: 'multipart/form-data',
		onSuccess: (result) => {
			return dispatch(uploadFileSuccess(result));
		},
		onError: (error) => {
			return dispatch(uploadFileError(error))
		},
	});
};

export const uploadUserFile = (file) => (dispatch, getState) => {
	let url = `https://api.violerts.com/api/user/files`;
	let state = getState();
	dispatch(uploadFileRequest());
	return upload({
		dispatch,
		url,
		data: file,
		authToken: state.user.authToken,
		contentType: 'multipart/form-data',
		onSuccess: (result) => {
			return dispatch(uploadFileSuccess(result));
		},
		onError: (error) => {
			return dispatch(uploadFileError(error))
		},
	});
}

export const uploadPropertyFile = (propertyId, file) => (dispatch, getState) => {
	if (!propertyId) {
		return;
	}
	let url = `https://api.violerts.com/api/property/${propertyId}/files`;
	let state = getState();
	dispatch(uploadFileRequest());
	return upload({
		dispatch,
		url,
		data: file,
		authToken: state.user.authToken,
		contentType: 'multipart/form-data',
		onSuccess: (result) => {
			return dispatch(uploadFileSuccess(result));
		},
		onError: (error) => {
			return dispatch(uploadFileError(error))
		},
	});
}

export const uploadFileRequest = (file) => ({
	type: POST_FILE_REQUEST,
	payload: {
		isUploading: true,
		hasError: false,
		error: null,
	}
});

export const uploadFileSuccess = (data) => ({
	type: POST_FILE_SUCCESS,
	payload: {
		isUploading: false,
		hasError: false,
		error: null,
	}
});

export const uploadFileError = (error) => ({
	type: POST_FILE_ERROR,
	payload: {
		isUploading: false,
		hasError: true,
		error: error,
	}
});

export const getFilesRequest = () => ({
	type: GET_FILES_REQUEST,
	payload: {
		isLoading: true,
		hasError: false,
		error: null,
	}
});

// export const getUserFilesRequest = () => ({
// 	type: GET_USER_FILES_REQUEST,
// 	payload: {
// 		isLoading: true,
// 		hasError: false,
// 		error: null,
// 	}
// });

export const getUserFilesSuccess = (data) => ({
	type: GET_USER_FILES_SUCCESS,
	payload: {
		isLoading: false,
		userFiles: data,
		hasError: false,
		error: null,
	}
});

export const getUserFilesError = (error) => ({
	type: GET_USER_FILES_ERROR,
	payload: {
		isLoading: false,
		userFiles: [],
		hasError: true,
		error: error,
	}
});

export const getAdminFilesSuccess = (data) => ({
	type: GET_ADMIN_FILES_SUCCESS,
	payload: {
		isLoading: false,
		adminFiles: data,
		hasError: false,
		error: null,
	}
});

export const getAdminFilesError = (error) => ({
	type: GET_ADMIN_FILES_ERROR,
	payload: {
		isLoading: false,
		adminFiles: [],
		hasError: true,
		error: error,
	}
});

// export const getPropertyFilesRequest = () => ({
// 	type: GET_PROPERTY_FILES_REQUEST,
// 	payload: {
// 		isLoading: true,
// 		hasError: false,
// 		error: null,
// 	}
// });

export const getPropertyFilesSuccess = (newPropertyFiles) => ({
	type: GET_PROPERTY_FILES_SUCCESS,
	payload: {
		isLoading: false,
		propertyFiles: newPropertyFiles,
		hasError: false,
		error: null,
	}
});

export const getPropertyFilesError = (error) => ({
	type: GET_PROPERTY_FILES_ERROR,
	payload: {
		isLoading: false,
		hasError: true,
		error: error,
	}
});

export const getDatasetItemFilesSuccess = (datasetItemFiles) => ({
	type: GET_DATASET_ITEM_FILES_SUCCESS,
	payload: {
		isLoading: false,
		datasetItemFiles,
		hasError: false,
		error: null,
	}
});

export const getDatasetItemFilesError = (error) => ({
	type: GET_DATASET_ITEM_FILES_ERROR,
	payload: {
		isLoading: false,
		datasetItemFiles: null,
		hasError: true,
		error: error,
	}
});

export const clearDatasetItemFiles = () => ({
	type: CLEAR_DATASET_ITEM_FILES,
	payload: {
		isLoading: false,
		datasetItemFiles: null,
		hasError: false,
		error: null,
	}
});

export const deleteFileRequest = () => ({
	type: DELETE_FILE_REQUEST,
	payload: {
		isLoading: true,
	}
});

export const deleteFileSuccess = () => ({
	type: DELETE_FILE_SUCCESS,
	payload: {
		isLoading: false,
	}
});

export const deleteFileError = (error) => ({
	type: DELETE_FILE_ERROR,
	payload: {
		isLoading: false,
		hasError: true,
		error: error,
	}
});

const filesReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_FILES_REQUEST:
		case GET_USER_FILES_SUCCESS:
		case GET_USER_FILES_ERROR:
		case GET_PROPERTY_FILES_SUCCESS:
		case GET_PROPERTY_FILES_ERROR:
		case GET_ADMIN_FILES_SUCCESS:
		case GET_ADMIN_FILES_ERROR:
		case POST_FILE_REQUEST:
		case POST_FILE_SUCCESS:
		case POST_FILE_ERROR:
		case GET_DATASET_ITEM_FILES_SUCCESS:
		case GET_DATASET_ITEM_FILES_ERROR:
		case CLEAR_DATASET_ITEM_FILES:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default filesReducer;
