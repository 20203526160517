import { useSelector } from 'react-redux';

import Badge from 'components/Badge';

import OverviewCard from 'components/OverviewCard';

import { numberWithCommas,getPenalties, getRefunds } from 'utils/amountsDueUtils';

import './AmountsDueCard.css';

function AmountBadge({ amount, isRefund }) {
	if (amount === 0) {
		return `$${amount}`;
	} else if (!amount) {
		return `-`;
	} else if (isRefund) {
		return <Badge color="success">${numberWithCommas(Math.abs(amount))}</Badge>;
	}
	return <Badge color="danger">${numberWithCommas(Math.abs(amount))}</Badge>;
}

function PropertyDocRequestCard(props) {
	// const { isLandingPage } = props;

	const {
		totals,
	} = useSelector(state => ({
		totals: state.property.propertyData?.totals,
	}));

	let penalties = getPenalties(totals);
	let refunds = getRefunds(totals);

	return (
		<OverviewCard title="Penalties and Refunds">
			<div className="amounts-due-card">
				<table className="table">
					<thead>
						<tr>
							<td />
							<td>Refunds</td>
							<td>Penalties</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>ECB Violations (Open Data)</td>
							<td><AmountBadge amount={refunds.ecbOpenData} isRefund /></td>
							<td><AmountBadge amount={penalties.ecbOpenData} /></td>
						</tr>
						<tr>
							<td>ECB Violations (BIS)</td>
							<td><AmountBadge amount={refunds.ecbBis} isRefund /></td>
							<td><AmountBadge amount={penalties.ecbBis} /></td>
						</tr>
						<tr className="amounts-due-row-above-total">
							<td>CityPay Tickets</td>
							<td><AmountBadge amount={refunds.citypay} isRefund /></td>
							<td><AmountBadge amount={penalties.citypay} /></td>
						</tr>
						<tr className="amounts-due-totals-row">
							<td className="font-weight-bold">Total</td>
							<td><AmountBadge amount={refunds.total} isRefund /></td>
							<td><AmountBadge amount={penalties.total} /></td>
						</tr>
					</tbody>
				</table>
			</div>
		</OverviewCard>
	);
}

export default PropertyDocRequestCard;
