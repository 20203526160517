import { useSelector } from 'react-redux';
// import { useDispatch, useSelector } from 'react-redux';
// import { Link } from "react-router-dom";

import { usePageTitle } from 'utils/pageTitle';
// import { getPropertyDocuments } from 'utils/propertyDocuments';
// import { openModal } from 'reducers/modalReducer';
// import { MODAL_TYPES } from 'view/ModalManager';
// import Spinner from 'components/Spinner';
// import OutboundLink from 'components/OutboundLink';
import PropertyDocumentsList from 'pages/overview/PropertyDocumentsList';

// function getCertificates(property) {
// 	if (!property) {
// 		return {};
// 	}

// 	//copy-paste from old violerts.com vue app
// 	return {
// 		"I-Card": { link: "https://hpdonline.hpdnyc.org/HPDonline/provide_address.aspx?p1="+ property.borough +"&p2="+ property.house_number +"&p3=" + property.street_name },
// 		"Certificate of Occupancy": { link: "http://a810-bisweb.nyc.gov/bisweb/COsByLocationServlet?requestid=0&allbin=" + property.bin },
// 		// "Landmark Designation Report": { prompt: true },
// 		"Landmark Designation Report": { link: 'https://www1.nyc.gov/site/lpc/designations/designation-reports.page' },
// 		"Letter of No Objection": { prompt: true },
// 		"Temporary Use Permit": { prompt: true },
// 		"Building Card": { prompt: true },
// 		"Sanborn Map": { prompt: true },
// 		"Historical Images": { prompt: true },
// 		"Surveys": { prompt: true },
// 		"Asbestos Reports": { prompt: true },
// 		"Equipment Use Permits": { comingSoon: true },
// 		"FDNY Permits (Annually Paid)": { comingSoon: true },
// 		"Public Assembly": { comingSoon: true },
// 		"Property Card": { comingSoon: true },
// 		"Docket Book": { comingSoon: true },
// 		"Tax Map": { comingSoon: true }
// 	};
// };

// function CertificateItem(props) {
// 	const dispatch = useDispatch();

// 	const openRequestDocumentModal = (documentName) => {
// 		return dispatch(openModal(MODAL_TYPES.DOCUMENT_REQUEST, { documentName }));
// 	};

// 	if (props.link) {
// 		return (
// 			<div>
// 				<OutboundLink url={props.link} label={props.certificateName} />
// 			</div>
// 		);
// 	} else if (props.prompt) {
// 		// if (!props.isLoggedIn) {
// 		// 	return (
// 		// 		<div>
// 		// 			<div>{props.certificateName} - <span><Link to="/sign-up">Sign up</Link> to request</span></div>
// 		// 		</div>
// 		// 	);			
// 		// }

// 		return (
// 			<button className="btn-hyperlink" onClick={() => openRequestDocumentModal(props.certificateName)}>
// 				{props.certificateName}
// 			</button>
// 		);

// 	} else if (props.comingSoon) {
// 		return (
// 			<div>
// 				<div>{props.certificateName} - Coming soon</div>
// 			</div>
// 		);
// 	}
// 	return null;
// }

function PropertyCertificates(props) {
	const {
		// isLoggedIn,
		// // userData,
		// isLoading,
		// certificates,
		propertyData,
	} = useSelector(state => ({
		// isLoggedIn: !!state.user.userData?.id,
		// // userData: state.user.userData,
		// isLoading: state.property.isLoading,
		// // certificates: getCertificates(state.property.propertyData),
		// certificates: getPropertyDocuments(state.property.propertyData),
		propertyData: state.property.propertyData,
	}));
	// const dispatch = useDispatch();

	// const openRequestDocumentModal = (documentName) => {
	// 	return dispatch(openModal(MODAL_TYPES.DOCUMENT_REQUEST, { documentName }));
	// };

	usePageTitle('Proerty Documents', propertyData);
	return (
		<div className="property-overview property-certificates">
			<div className="card">
				<div className="card-header">
					Property Documents
				</div>
				<div className="card-body">

					<PropertyDocumentsList />
{/*					{isLoading && <Spinner />}
					{certificates.map(certificateName => (
						<div key={certificateName} className="mb-2">
							<button className="btn-hyperlink" onClick={() => openRequestDocumentModal(certificateName)}>
								{certificateName}
							</button>
						</div>
					))}*/}
{/*
					{Object.keys(certificates).map((certificateName) => {
						return (
							<div key={certificateName} className="mb-2">
								<CertificateItem isLoggedIn={isLoggedIn} certificateName={certificateName} {...certificates[certificateName]} />
							</div>
						);
					})}*/}
				</div>
			</div>
		</div>
	);
}

export default PropertyCertificates;
