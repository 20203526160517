// import { Link } from "react-router-dom";
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchBinsWithTopViolations } from 'reducers/topViolationsReducer';
import Spinner from 'components/Spinner';
import Paginator from 'components/Paginator';
import OutboundLink from 'components/OutboundLink';
import { getTopViolationsExcelExportUrl } from 'data/services/exportService';


const AdminTopViolationsCard = (props) => {
	const dispatch = useDispatch();
	let {
		topViolations,
		isLoading,
		currentPage,
		totalPages,
	} = useSelector(state => ({
		topViolations: state.topViolations.data?.data || [],
		isLoading: state.topViolations.isLoading,
		currentPage: state.topViolations.data?.current_page,
		totalPages: state.topViolations.data?.last_page,
	}));

	useEffect(() => {
		dispatch(fetchBinsWithTopViolations());
	}, [ dispatch]);

	return (
		<div className="card">
			<div className="card-header">
				Marketing - Top BINs with Open Violations
			</div>
			<div className="card-body">
				<div>
					{topViolations?.length ? <a className="export-link" href={getTopViolationsExcelExportUrl()}>Download Excel</a> : null}
				</div>
				{isLoading && <Spinner />}
				<div className="dashboard-table-wrapper">
					<table className="table table-striped">
						<thead><tr>
							<th>BIN</th>
							<th>Address</th>
							<th>Open Violations</th>
						</tr></thead>
						<tbody>
							{topViolations.map((item, i) => (
								<tr key={`${item.bin}-${i}`}>
									<td>
										{item.bin ? (
											<OutboundLink
												url={`http://a810-bisweb.nyc.gov/bisweb/PropertyProfileOverviewServlet?bin=${item.bin}&go2=+GO+&requestid=0`}
												label={item.bin}
											/>
										) : '-'}
									</td>
									<td>{item.address || '-'}</td>
									<td>{item.numViolations || '-'}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<Paginator
					currentPage={currentPage}
					totalPages={totalPages}
					setPage={(pageNum) => dispatch(fetchBinsWithTopViolations('numViolations|desc', pageNum, 10))}
				/>
			</div>
		</div>

	);
}

export default AdminTopViolationsCard;
