import { useState, useEffect, useRef } from 'react';

import './Dropdown.css';

function Dropdown(props) {
	let {
		buttonText,
		buttonIcon,
		menuItems,
		hideChevron,
		onToggle,
	} = props;
	
	const containerRef = useRef(null);
	const [isOpen, setIsOpen] = useState(false);

	function handleClickOutside(event) {
		if (containerRef.current && !containerRef.current.contains(event.target)) {
			handleToggle(false);
		}
	}

	function handleToggle(open) {
		if (typeof onToggle === 'function') {
			onToggle(open);
		}
		setIsOpen(open);
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, [containerRef]);

	return (
		<div className="vio-dropdown-container" ref={containerRef}>
			<button className="vio-dropdown-button btn btn-primary" onClick={() => handleToggle(!isOpen)}>
				{buttonIcon && <span className="vio-dropdown-button-icon"><i className={`bi ${buttonIcon}`} /></span>}
				<span>{buttonText}</span>
				{!hideChevron && <i className={`bi bi-chevron-${isOpen ? 'up' : 'down'}`} />}
			</button>
			{isOpen &&
				<div className="vio-dropdown-menu">
					{menuItems.map((item, i) => (
						<div key={i} className="vio-dropdown-menu-item">
							<button onClick={() => { item.onClick(); handleToggle(false); }}>
								{item.icon && <span className="vio-dropdown-menu-item-icon"><i className={`bi ${item.icon}`} /></span>}
								<span>{item.text}</span>
							</button>
						</div>
					))}
				</div>
			}
		</div>
	);
}

export default Dropdown;
