import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchInfractionCodeDetails } from 'reducers/ecbInfractionCodeReducer';

import Spinner from 'view/components/Spinner';
import OutboundLink from 'view/components/OutboundLink';
import StandardModal from 'modals/StandardModal';

const prettyFieldName = {
	infraction_code: "Code",
	section_of_law: "Section of Law",
	classification: "Classification",
	violation_description: "Description",
	remedy: "Remedy",
	cure: "Cure",
	stipulation: "Stipulation",
	standard_penalty: "Standard Penalty",
	mitigated_penalty: "Mitigated Penalty",
	default_penalty: "Default Penalty",
	aggravated_i_penalty: "Aggravated I Penalty",
	aggravated_i_default_penalty: "Aggravated I Default Penalty",
	aggravated_ii_penalty: "Aggravated II Penalty",
	aggravated_ii_default_max_penalty: "Aggravated II Default Penalty"
};

function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function isNumber(str) {
	return  /^\d+$/.test(str);
}

function formatValue(key, val) {
	if (key.includes('penalty') && isNumber(val)) {
		return `$${numberWithCommas(val)}`;
	}
	return val;
}

const ECBInfractionCodeModal = ({ options }) => {
	const dispatch = useDispatch();
	const { isLoading, infractionData } = useSelector(state => ({
		isLoading: state.ecbInfractionCode.isLoading,
		infractionData: state.ecbInfractionCode.data,
	}))

	useEffect(() => dispatch(fetchInfractionCodeDetails(options.codeId)), [dispatch, options.codeId]);

	let modalContent = (
		<div>
			<div>Details not found for this infraction code.</div>
			<OutboundLink url="https://ecb.vio.nyc/" label="More information may be found here." noIcon />
		</div>
	);

	if (isLoading) {
		modalContent = <Spinner text="Getting infraction details" />
	} else if (infractionData) {
		modalContent = (
			<table className="table table-striped data-table">
				<tbody>
					{Object.keys(infractionData).map(key => (
						<tr key={key}>
							<td>{prettyFieldName[key] || key}</td>
							<td>{formatValue(key, infractionData[key])}</td>
						</tr>
					))}
				</tbody>
			</table>
		)
	}

	return (
		<StandardModal
			title="Infraction Code Description"
			size="lg"
			closeOnOutsideClick
		>
			{modalContent}
		</StandardModal>
	);
};

export default ECBInfractionCodeModal;
