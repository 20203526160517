import { useSelector } from 'react-redux';

import AcrisRealPropertyMasterModal from 'modals/AcrisRealPropertyMasterModal';
import UserAdminModal from 'modals/UserAdminModal';
import AddToCartModal from 'modals/AddToCartModal';
import CalendarEventModal from 'modals/CalendarEventModal';
import ConfirmModal from 'modals/ConfirmModal';
import DocumentRequestModal from 'modals/DocumentRequestModal';
import ECBInfractionCodeModal from 'modals/ECBInfractionCodeModal';
import ExportReportModal from 'modals/ExportReportModal';
import FDNYReportModal from 'modals/FDNYReportModal';
import GetStartedModal from 'modals/GetStartedModal';
import OwnersModal from 'modals/OwnersModal';
import PropertyFileUploadModal from 'modals/PropertyFileUploadModal';
import PropertyNotificationsModal from 'modals/PropertyNotificationsModal';
import RecordDetailsModal from 'modals/RecordDetailsModal';
import SpecialDistrictsModal from 'modals/SpecialDistrictsModal';
import UploadModal from 'modals/UploadModal';

import 'modals/Modal.css';

export const MODAL_TYPES = {
	ACRIS_REAL_PROPERTY_MASTER: 'ACRIS_REAL_PROPERTY_MASTER',
	ADD_TO_CART: 'ADD_TO_CART',
	CALENDAR_EVENT: 'CALENDAR_EVENT',
	CONFIRM: 'CONFIRM',
	DOCUMENT_REQUEST: 'DOCUMENT_REQUEST',
	ECB_INFRACTION_CODE: 'ECB_INFRACTION_CODE',
	EXPORT_REPORT: 'EXPORT_REPORT',
	FDNY_REPORT: 'FDNY_REPORT',
	GET_STARTED: 'GET_STARTED',
	OWNERS: 'OWNERS',
	PROPERTY_FILE_UPLOAD_MODAL: 'PROPERTY_FILE_UPLOAD_MODAL',
	PROPERTY_NOTIFICATION_MODAL: 'PROPERTY_NOTIFICATION_MODAL',
	RECORD_DETAILS: 'RECORD_DETAILS',
	SPECIAL_DISTRICTS: 'SPECIAL_DISTRICTS',
	UPLOAD: 'UPLOAD',
	USER_ADMIN: 'USER_ADMIN',
};

const ModalManager = (props) => {
	const {
		currentModal,
		isOpen,
		modalOptions,
	} = useSelector(state => ({
		currentModal: state.modal.currentModal,
		isOpen: state.modal.isOpen,
		modalOptions: state.modal.modalOptions,
	}));

	if (isOpen && currentModal) {
		switch (currentModal) {
			case MODAL_TYPES.ACRIS_REAL_PROPERTY_MASTER:
				return <AcrisRealPropertyMasterModal options={modalOptions} />;
			case MODAL_TYPES.ADD_TO_CART:
				return <AddToCartModal options={modalOptions} />;
			case MODAL_TYPES.CALENDAR_EVENT:
				return <CalendarEventModal options={modalOptions} />;
			case MODAL_TYPES.CONFIRM:
				return <ConfirmModal options={modalOptions} />;
			case MODAL_TYPES.DOCUMENT_REQUEST:
				return <DocumentRequestModal options={modalOptions} />;
			case MODAL_TYPES.ECB_INFRACTION_CODE:
				return <ECBInfractionCodeModal options={modalOptions} />;
			case MODAL_TYPES.EXPORT_REPORT:
				return <ExportReportModal options={modalOptions} />;
			case MODAL_TYPES.FDNY_REPORT:
				return <FDNYReportModal options={modalOptions} />;
			case MODAL_TYPES.GET_STARTED:
				return <GetStartedModal options={modalOptions} />;
			case MODAL_TYPES.OWNERS:
				return <OwnersModal options={modalOptions} />;
			case MODAL_TYPES.PROPERTY_FILE_UPLOAD_MODAL:
				return <PropertyFileUploadModal options={modalOptions} />;
			case MODAL_TYPES.PROPERTY_NOTIFICATION_MODAL:
				return <PropertyNotificationsModal options={modalOptions} />;
			case MODAL_TYPES.RECORD_DETAILS:
				return <RecordDetailsModal options={modalOptions} />;
			case MODAL_TYPES.SPECIAL_DISTRICTS:
				return <SpecialDistrictsModal options={modalOptions} />;
			case MODAL_TYPES.UPLOAD:
				return <UploadModal options={modalOptions} />;
			case MODAL_TYPES.USER_ADMIN:
				return <UserAdminModal options={modalOptions} />;
			default:
				return null
		}
	}

	return null;
};

export default ModalManager;
