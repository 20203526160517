import { get } from 'data/services/apiService';

const GET_ZONING_DATA_REQUEST = 'GET_ZONING_DATA_REQUEST';
const GET_ZONING_DATA_SUCCESS = 'GET_ZONING_DATA_SUCCESS';
const GET_ZONING_DATA_ERROR = 'GET_ZONING_DATA_ERROR';

const initialState = {
	data: null,
	isLoading: false,
	hasError: false,
	error: null,
};


// const BOROUGH_SUBDOMAINS = {
// 	1: 'mn',
// 	2: 'bx',
// 	3: 'bk',
// 	4: 'qn',
// 	5: 'si',
// };

//old zoning api(s) on another server. this might get turned off in the future
// export const fetchZoningDataByBlockLot = (block, lot, boroughNum) => (dispatch) => {
// 	let url = `https://${BOROUGH_SUBDOMAINS[boroughNum]}-zone.vio.nyc/block_lot/${block}/${lot}`;
// 	dispatch(getZoningDetailsRequest());
// 	return get({
// 		dispatch,
// 		url,
// 		onSuccess: result => dispatch(getZoningDetailsSuccess(result)),
// 		onError: error => dispatch(getZoningDetailsError(error)),
// 	});
// };

export const fetchZoningData = (propertyId) => (dispatch) => {
	let url = `https://api.violerts.com/api/properties/${propertyId}/zoning`;
	dispatch(getZoningDetailsRequest());
	return get({
		dispatch,
		url,
		onSuccess: result => dispatch(getZoningDetailsSuccess(result)),
		onError: error => dispatch(getZoningDetailsError(error)),
	});
};


export const getZoningDetailsRequest = () => ({
	type: GET_ZONING_DATA_REQUEST,
	payload: {
		isLoading: true,
		data: null,
	}
});

export const getZoningDetailsSuccess = (data) => ({
	type: GET_ZONING_DATA_SUCCESS,
	payload: {
		isLoading: false,
		hasError: false,
		data,
		error: null,
	}
});

export const getZoningDetailsError = (error) => ({
	type: GET_ZONING_DATA_ERROR,
	payload: {
		isLoading: false,
		data: null,
		hasError: true,
		error: error,
	}
});

const zoningReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ZONING_DATA_REQUEST:
		case GET_ZONING_DATA_SUCCESS:
		case GET_ZONING_DATA_ERROR:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default zoningReducer;
