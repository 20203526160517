import { get } from 'data/services/apiService';
import { sortToString, DEFAULT_OWNERS_SORT } from 'utils/sort';

const GET_OWNERS_REQUEST = 'GET_OWNERS_REQUEST';
const GET_OWNERS_SUCCESS = 'GET_OWNERS_SUCCESS';
const GET_OWNERS_ERROR = 'GET_OWNERS_ERROR';

const GET_MOST_RECENT_OWNER_REQUEST = 'GET_MOST_RECENT_OWNER_REQUEST';
const GET_MOST_RECENT_OWNER_SUCCESS = 'GET_MOST_RECENT_OWNER_SUCCESS';
const GET_MOST_RECENT_OWNER_ERROR = 'GET_MOST_RECENT_OWNER_ERROR';

const initialState = {
	propertyId: null,
	data: null,
	mostRecentOwner: null,
	isLoading: false,
	hasError: false,
	error: null,
};

export const fetchOwners = (propertyId, { sort, page=1, perPage=10, dataSource }={}) => (dispatch) => {
	let sortString = sortToString(sort, DEFAULT_OWNERS_SORT);
	let url = `https://api.violerts.com/api/property/${propertyId}/owners?sort=${sortString}&page=${page}&per_page=${perPage}`;
	// let url = `https://api.violerts.com/api/property/${propertyId}/owners?page=${page}&per_page=${perPage}`;

	if (dataSource) {
		url += `&set=${dataSource}`;
	}

	dispatch(getOwnersRequest(propertyId));
	return get({
		dispatch,
		url,
		onSuccess: (result) => {
			dispatch(getOwnersRequestSuccess(result))
		},
		onError: error => dispatch(getOwnersRequestError(error)),
	});
};

export const fetchMostRecentOwner = (propertyId) => (dispatch) => {
	let url = `https://api.violerts.com/api/property/${propertyId}/owners?sort=date|desc&page=1&per_page=1`;
	dispatch(getMostRecentOwnerRequest(propertyId));
	return get({
		dispatch,
		url,
		onSuccess: (result) => {
			dispatch(getMostRecentOwnerSuccess(result.data[0]));
		},
		onError: error => dispatch(getMostRecentOwnerError(error)),
	});
};

export const getOwnersRequest = (propertyId) => ({
	type: GET_OWNERS_REQUEST,
	payload: {
		propertyId,
		isLoading: true,
		data: null,
	}
});

export const getOwnersRequestSuccess = (data) => ({
	type: GET_OWNERS_SUCCESS,
	payload: {
		isLoading: false,
		hasError: false,
		data,
		error: null,
	}
});

export const getOwnersRequestError = (error) => ({
	type: GET_OWNERS_ERROR,
	payload: {
		isLoading: false,
		data: null,
		hasError: true,
		error: error,
	}
});

export const getMostRecentOwnerRequest = (data) => ({
	type: GET_MOST_RECENT_OWNER_REQUEST,
	payload: {
		isLoading: true,
		hasError: false,
		mostRecentOwner: null,
		error: null,
	}
});

export const getMostRecentOwnerSuccess = (mostRecentOwner) => ({
	type: GET_MOST_RECENT_OWNER_SUCCESS,
	payload: {
		isLoading: false,
		hasError: false,
		mostRecentOwner: mostRecentOwner,
		error: null,
	}
});

export const getMostRecentOwnerError = (error) => ({
	type: GET_MOST_RECENT_OWNER_ERROR,
	payload: {
		isLoading: false,
		hasError: true,
		mostRecentOwner: null,
		error: error,
	}
});

const ownersReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_OWNERS_REQUEST:
		case GET_OWNERS_SUCCESS:
		case GET_OWNERS_ERROR:
		case GET_MOST_RECENT_OWNER_REQUEST:
		case GET_MOST_RECENT_OWNER_SUCCESS:
		case GET_MOST_RECENT_OWNER_ERROR:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default ownersReducer;
