import './Spinner.css';

function Spinner({ text, size, color, className }) {
	let spinnerSizeClass = ''

	if (size === 'sm') {
		spinnerSizeClass = `spinner-${size}`;
	}

	color = color || 'primary';

	return (
		<div className={`spinner-container ${className ? className : ''}`}>
			<div className="spinner-wrapper">
				<div className={`spinner-border text-${color} ${spinnerSizeClass}`} />
				{text && <div className="spinner-text">{text}</div>}
			</div>
		</div>
	);
}

export default Spinner;
