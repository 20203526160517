import OverviewCard from 'components/OverviewCard';
import ExternalLinksList from 'pages/overview/ExternalLinksList';

function LinksCard({ listName='all', title='Links' }) {
	return (
		<OverviewCard title={title}>
			<ExternalLinksList listName={listName} />
		</OverviewCard>
	);
}

export default LinksCard;
