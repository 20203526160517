import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';

import { usePageTitle } from 'utils/pageTitle';
import AdminFileCard from 'pages/dashboard/AdminFileCard';
import AdminTopViolationsCard from 'pages/dashboard/AdminTopViolationsCard';
import AdminUserManagementCard from 'pages/dashboard/AdminUserManagementCard';
import AdminPortfolioManagementCard from 'pages/dashboard/AdminPortfolioManagementCard';
import AdminNotificationManagementCard from 'pages/dashboard/AdminNotificationManagementCard';
 
import './Admin.css';

const Admin = (props) => {
	usePageTitle('Admin');
	const history = useHistory();
	let {
		isLoggedIn,
		// userData,
	} = useSelector(state => ({
		isLoggedIn: !!state.user.userData?.id,
		// userData: state.user.userData,
	}));

	const [selectedTab, setSelectedTab] = useState('topViolations');

	useEffect(() => {
		if (!isLoggedIn) {
			history.replace('/sign-in');
		}
	}, [isLoggedIn, history]);

	let content = <AdminTopViolationsCard />;

	if (selectedTab === 'topViolations') {
		content = <AdminTopViolationsCard />;
	} else if (selectedTab === 'users') {
		content = <AdminUserManagementCard />;
	} else if (selectedTab === 'portfolios') {
		content = <AdminPortfolioManagementCard />;
	} else if (selectedTab === 'notifications') {
		content = <AdminNotificationManagementCard />;
	} else if (selectedTab === 'files') {
		content = <AdminFileCard />;
	}

	const onSelectTab = (tab) => {
		setSelectedTab(tab);
	};

	return (
		<div className="dashboard admin">
			<div className="dashboard-content">
				<h4 className=""><i className="bi bi-people me-2" />Admin</h4>
				<ul className="nav nav-tabs">
					<li className="nav-item">
						<span onClick={() => onSelectTab('topViolations')} className={`nav-link ${selectedTab === 'topViolations' ? 'active': ''}`}>Top Violations</span>
					</li>
					<li className="nav-item">
						<span onClick={() => onSelectTab('users')} className={`nav-link ${selectedTab === 'users' ? 'active': ''}`}>Users</span>
					</li>
					<li className="nav-item">
						<span onClick={() => onSelectTab('portfolios')} className={`nav-link ${selectedTab === 'portfolios' ? 'active': ''}`}>Portfolios</span>
					</li>
					<li className="nav-item">
						<span onClick={() => onSelectTab('notifications')} className={`nav-link ${selectedTab === 'notifications' ? 'active': ''}`}>Notifications</span>
					</li>
					<li className="nav-item">
						<span onClick={() => onSelectTab('files')} className={`nav-link ${selectedTab === 'files' ? 'active': ''}`}>Files</span>
					</li>
				</ul>
				<div>
					<div className="card-wrapper">
						{content}
					</div>
				</div>
			</div>
		</div>
	);	


}

export default Admin;
