import { Link, useParams, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

import { getNavRoutes } from 'routes/navRoutes'
import DataSetLinks from 'components/sidebar/DataSetLinks';

import './Sidebar.css';

function Sidebar(props) {
	let { address } = useParams();
	let { isLandingPage } = props;
	let location = useLocation();
	let pathParts = location?.pathname?.split('/') || [];
	let currentRoute = pathParts[3];
	let {
		isLoggedIn,
		propertyData,
	} = useSelector(state => ({
		isLoggedIn: !!state.user.userData?.id,
		propertyData: state.property.propertyData,
	}));

	return (
		<div className="left-nav">
			{propertyData?.thumbnail_url && (
				<div className="property-image-wrapper">
					<img className="property-image" src={`https://api.violerts.com${propertyData?.thumbnail_url}`} alt="Building thumbnail" />
				</div>
			)}
			<ul>
				{getNavRoutes(address, isLoggedIn).map((route) => {
					/* this logic can fail if a page that shows the sidebar has a route with
					   a format other than /property/:address/:currentRoute
					*/
					let routeParts = route.url.split('/') || [];
					let isActive = !!routeParts[3] && routeParts[3] === currentRoute;
					return (
						<li key={route.url}>
							{isLandingPage ? <span className="left-nav-button link-disabled">{route.label}</span> :
								<Link className={`left-nav-button ${isActive ? 'is-active' : ''}`} to={route.url}>
									{route.label}
								</Link>
							}

						</li>
					);
				})}
				<li className="divider" />
				<DataSetLinks isLandingPage={isLandingPage} />
			</ul>
		</div>
	);
}

export default Sidebar;
