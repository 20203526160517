import './Paginator.css';

function Paginator(props) {
	let {
		currentPage,
		totalPages,
		setPage,
	} = props;

	let hasNextPage = currentPage < totalPages;
	let hasPreviousPage = currentPage > 1;

	function getNextPage() {
		if (hasNextPage) {
			setPage(currentPage + 1);
		}
	}

	function getPreviousPage() {
		if (currentPage > 1) {
			setPage(currentPage - 1);
		}
	}

	//todo: hide some pages when there is a lot
	let pageButtons = [];
	for (let i = 1; i <= totalPages; i++) {
		pageButtons.push(
			<li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
				<span className="page-link" onClick={() => setPage(i)}>{i}</span>
			</li>
		);
	}

	return (
		<nav className="paginator">
			<ul className="pagination">
				<li className={`page-item ${hasPreviousPage ? '' : 'disabled'}`}>
					<span className="page-link" onClick={() => getPreviousPage()}>Previous</span>
				</li>
				{pageButtons}
				<li className={`page-item ${hasNextPage ? '' : 'disabled'}`}>
					<span className="page-link" onClick={() => getNextPage()}>Next</span>
				</li>
			</ul>
		</nav>
	);
}

export default Paginator;
