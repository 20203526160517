import Routes from 'routes/Routes';
import { initDarkMode } from 'data/services/darkMode';
import { initGoogleAnalytics } from 'utils/googleAnalytics';

import './App.css';
import './Theme.css';


function App() {
	initDarkMode();
	initGoogleAnalytics();
	return <Routes />;
}

export default App;
