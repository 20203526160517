import './OverviewCard.css';

function OverviewCard(props) {
	return (
		<div className="overview-card-container">
			<div className="overview-card card">
				<div className="overview-card-header card-header">
					{props.title}
				</div>
				<div className={`overview-card-body card-body ${props.isLoading ? 'is-loading' : ''}`}>
					{props.children}
				</div>
			</div>
		</div>
	);
}

export default OverviewCard;
