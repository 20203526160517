import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePageTitle } from 'utils/pageTitle';
import { fetchPortfolioSettingsByPropertyId, setNotification } from 'reducers/portfolioSettingsReducer';
import { fetchPortfolio } from 'reducers/portfolioReducer';
import Spinner from 'components/Spinner';
import Toggle from 'components/Toggle';
import SignUpCTA from 'components/SignUpCTA';

const NotificationsPage = (props) => {
	const dispatch = useDispatch();
	const {
		isLoading,
		portfolioSettings,
		property,
		propertyId,
		userId,
	} = useSelector(state => ({
		isLoading: state.portfolioSettings.isLoading || state.portfolioSettings.isRemovingProperty,
		portfolioSettings: state.portfolioSettings.data || {},
		property: state.property.propertyData,
		propertyId: state.property.propertyData?.id,
		userId: state.user.userData?.id,
	}));
	usePageTitle('Email Alerts', property);

	useEffect(() => {
		if (userId) {
			dispatch(fetchPortfolio(userId));
		}
	}, [userId, dispatch]);

	useEffect(() => {
		if (propertyId && userId) {
			dispatch(fetchPortfolioSettingsByPropertyId(propertyId));
		}
	}, [propertyId, userId, dispatch]);

	const handleToggle = (notificationId, isEnabled) => {
		dispatch(setNotification(portfolioSettings.id, notificationId, isEnabled));
	};

	let content = (
		<div className="card-body">
			{isLoading && <Spinner />}
			<h6>{property?.address}</h6>
			<div>
				{portfolioSettings.notificationTypes?.map(notificationType => (
					<Toggle
						key={notificationType.id}
						label={notificationType.name}
						isEnabled={notificationType.enabled === 1}
						onChange={(isEnabled) => handleToggle(notificationType.id, isEnabled)}
					/>
				))}
			</div>
		</div>
	);

	if (!userId) {
		content = <SignUpCTA text="Sign up to receive email alerts" />
	}

	return (	
		<div className="property-overview">
			<div className="card">
				<div className="card-header">
					Notifications
				</div>
				{content}
			</div>
		</div>
	);
}

export default NotificationsPage;
