import { OPEN_DATA, BIS } from 'data/dataSourceConstants';
import OutboundLink from 'components/OutboundLink';

function AEULink({ violationNumber, formType, dataSource }) {
	let url = '';
	if (dataSource === BIS) {
		url = `https://api.violerts.com/api/ecb-violations/${violationNumber}/${formType}`;	
	} else if (dataSource === OPEN_DATA) {
		url = `https://api.violerts.com/api/open-data/ecb-violations/${violationNumber}/${formType}`;	
	} else {
		return '-';
	}
	
	return <OutboundLink url={url} iconOnly />;
}

export const AEU2Link = (props) => {
	return AEULink({
		violationNumber: props.text,
		formType: 'aeu2',
		dataSource: props.dataSource,
	});
};

export const AEU20Link = (props) => {
	return AEULink({
		violationNumber: props.text,
		formType: 'aeu20',
		dataSource: props.dataSource,
	});
};
