import { useHistory } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { usePageTitle } from 'utils/pageTitle';
import PortfolioCard from 'pages/dashboard/PortfolioCard';
import NotificationsCard from 'pages/dashboard/NotificationsCard';
 
import './Dashboard.css';

const Dashboard = (props) => {
	usePageTitle('Dashboard');
	const history = useHistory();
	let {
		isLoggedIn,
	} = useSelector(state => ({
		isLoggedIn: !!state.user.userData?.id,
	}));


	const [selectedTab, setSelectedTab] = useState('portfolio');

	useEffect(() => {
		if (!isLoggedIn) {
			history.replace('/sign-in');
		}
	}, [isLoggedIn, history]);

	let content = <PortfolioCard />;

	if (selectedTab === 'portfolio') {
		content = <PortfolioCard />;
	} else if (selectedTab === 'notifications') {
		content = <NotificationsCard />;
	}

	const onSelectTab = (tab) => {
		setSelectedTab(tab);
	};

	let loggedInContent = (
		<div className="card-wrapper">
			{content}
		</div>
	);

	let loggedOutContent = <div>You're not logged in!</div>;

	return (
		<div className="dashboard">
			<div className="dashboard-content">
				<h4 className=""><i className="bi bi-clipboard-data me-2" />Dashboard</h4>
				<ul className="nav nav-tabs">
					<li className="nav-item">
						<span onClick={() => onSelectTab('portfolio')} className={`nav-link ${selectedTab === 'portfolio' ? 'active': ''}`}>Portfolio</span>
					</li>
					<li className="nav-item">
						<span onClick={() => onSelectTab('notifications')} className={`nav-link ${selectedTab === 'notifications' ? 'active': ''}`}>Notifications</span>
					</li>
				</ul>
				<div>
					{isLoggedIn ? loggedInContent : loggedOutContent}
				</div>
			</div>
		</div>
	);	


}

export default Dashboard;
