import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';

import cartMiddleware, { getCartState } from 'data/middleware/cartMiddleware';
import pageTrackerMiddleware from 'data/middleware/pageTrackerMiddleware';
import sessionMiddleware, { getSessionState } from 'data/middleware/sessionMiddleware';
import pusherMiddleware from 'data/middleware/pusherMiddleware';
import { dataConfig } from 'data/dataConfig';
import { getReducerName } from 'data/dataSourceConstants';

import genericDataSetReducer from 'reducers/genericDataSetReducer';
import acrisRealPropertyMasterReducer from 'reducers/acrisRealPropertyMasterReducer';
import addressReducer from 'reducers/addressReducer';
import adminPortfoliosReducer from 'reducers/adminPortfoliosReducer';
import adminNotificationsReducer from 'reducers/adminNotificationsReducer';
import adminUsersReducer from 'reducers/adminUsersReducer';
import cartReducer from 'reducers/cartReducer';
import complianceDatesReducer from 'reducers/complianceDatesReducer';
import documentRequestReducer from 'reducers/documentRequestReducer';
import ecbInfractionCodeReducer from 'reducers/ecbInfractionCodeReducer';
import fileReducer from 'reducers/fileReducer';
import firstLoadTasksReducer from 'reducers/firstLoadTasksReducer';
import modalReducer from 'reducers/modalReducer';
import ownersReducer from 'reducers/ownersReducer';
import notificationsReducer from 'reducers/notificationsReducer';
import portfolioReducer from 'reducers/portfolioReducer';
import portfolioSettingsReducer from 'reducers/portfolioSettingsReducer';
import propertyReducer from 'reducers/propertyReducer';
import recordDetailsReducer from 'reducers/recordDetailsReducer';
import recordSearchReducer from 'reducers/recordSearchReducer';
import sidebarReducer from 'reducers/sidebarReducer';
import topViolationsReducer from 'reducers/topViolationsReducer';
import userReducer from 'reducers/userReducer';
import zoningReducer from 'reducers/zoningReducer';

export const history = createBrowserHistory();

let reducers = {
	acrisRealPropertyMaster: acrisRealPropertyMasterReducer,
	address: addressReducer,
	adminNotifications: adminNotificationsReducer,
	adminPortfolios: adminPortfoliosReducer,
	adminUsers: adminUsersReducer,
	cart: cartReducer,
	complianceDates: complianceDatesReducer,
	documentRequest: documentRequestReducer,
	ecbInfractionCode: ecbInfractionCodeReducer,
	files: fileReducer,
	firstLoadTasks: firstLoadTasksReducer,
	modal: modalReducer,
	owners: ownersReducer,
	notifications: notificationsReducer,
	portfolio: portfolioReducer,
	portfolioSettings: portfolioSettingsReducer,
	property: propertyReducer,
	recordDetails: recordDetailsReducer,
	recordSearch: recordSearchReducer,
	sidebar: sidebarReducer,
	topViolations: topViolationsReducer,
	user: userReducer,
	zoning: zoningReducer,
};
export const actions = {};

const initialState = Object.assign({},
	getSessionState(),
	getCartState(),
);

Object.keys(dataConfig).forEach(key => {
	let configObj = dataConfig[key];
	configObj.dataSources.forEach(dataSource => {
			let { reducer, fetchData, setPage, silentRefresh } = genericDataSetReducer(configObj, dataSource);
			let reducerName = getReducerName(configObj, dataSource);
			reducers[reducerName] = reducer;
			actions[reducerName] = {
				fetchData,
				setPage,
				silentRefresh,
			};
	});
});

const createRootReducer = (history) => combineReducers({
	router: connectRouter(history),
	...reducers,
});

//hook up redux dev tools extension
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
	createRootReducer(history),
	initialState,
	composeEnhancers(applyMiddleware(
		routerMiddleware(history),
		cartMiddleware,
		sessionMiddleware,
		pageTrackerMiddleware,
		pusherMiddleware,
		thunk
	))
);

export const dispatch = store.dispatch;

window.store = () => store.getState(); //debug
window.actions = () => actions; //debug

export default store;