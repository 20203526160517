import { Link, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

import AccountDropdown from 'components/appHeader/AccountDropdown';
import HeaderSearch from 'components/appHeader/HeaderSearch';
import Logo from 'components/appHeader/Logo';
import Tooltip from 'components/Tooltip';

import './AppHeader.css';

const ContactIcon = ({ method }) => {
	if (method === 'phone') {
		return (
			<Tooltip text="1-212-401-6011" direction="bottom">
				<a href="tel:12124016011"><i className="bi bi-telephone"/></a>
			</Tooltip>
		);
	} else if (method === 'email') {
		return (
			<Tooltip text="admin@violerts.com" direction="bottom">
				<a href="mailto:admin@violerts.com"><i className="bi bi-envelope"/></a>
			</Tooltip>
		);
	}
}

const CartLink = ({ cartCount }) => {
	return (
		<span className="cart-link-wrapper">
			<Link to="/cart" className="cart-link"><i className="bi bi-cart3"/></Link>
			{cartCount > 0 && <span className="badge bg-danger">{cartCount}</span>}
		</span>
	);
}


const AppHeader = (props) => {
	const location = useLocation();
	const isLandingPage = location.pathname === '/';
	const hideHeaderSearch = props.hideHeaderSearch;
	const {
		propertyData,
		cartCount,
	} = useSelector(state => ({
		propertyData: state.property.propertyData,
		cartCount: state.cart.items.length,
	}));

	let logoLink = "/";

	//this is because the root url is supposed to be a blank overview page,
	//if we want to redirect to it, we would need to clear out all the redux data for the current property
	if (propertyData?.address) {
		logoLink = `/property/${propertyData?.address}/overview`;
	}

	return (
		<nav className="navbar navbar-dark top-nav app-header">
			<div className="container-fluid">
				<div>
					<span className="navbar-brand svg-logo-wrapper"><Link to={logoLink}><Logo /></Link></span>
					{/*<span className="navbar-brand svg-logo-wrapper"><Link to="/"><Logo /></Link></span>*/}
					{/*<span className="navbar-brand svg-logo-wrapper"><Logo /></span>*/}
					<span className="contact-icon-wrapper"><ContactIcon method="phone" /></span>
					<span className="contact-icon-wrapper"><ContactIcon method="email" /></span>
				</div>
				<span className="app-header-right">
					<HeaderSearch hideHeaderSearch={hideHeaderSearch} showHeaderHint={isLandingPage} />			
					<CartLink cartCount={cartCount} />
					<AccountDropdown />	
				</span>
			</div>
		</nav>
	);
};

export default AppHeader;
