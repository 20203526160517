import { get } from 'data/services/apiService';

const GET_COMPLIANCE_DATES_REQUEST = 'GET_COMPLIANCE_DATES_REQUEST';
const GET_COMPLIANCE_DATES_SUCCESS = 'GET_COMPLIANCE_DATES_SUCCESS';
const GET_COMPLIANCE_DATES_ERROR = 'GET_COMPLIANCE_DATES_ERROR';

const initialState = {
	propertyId: null,
	data: null,
	isLoading: false,
	hasError: false,
	error: null,
};

export const fetchComplianceDates = (propertyId) => (dispatch) => {
	let url = `https://api.violerts.com/api/property/${propertyId}/compliance-dates`;

	if (propertyId) {
		dispatch(getComplianceDatesRequest(propertyId));
	} else {
		url = `https://api.violerts.com/api/compliance-dates`;
		dispatch(getComplianceDatesRequest(null));
	}
	return get({
		dispatch,
		url,
		onSuccess: (result) => {
			dispatch(getComplianceDatesRequestSuccess(result))
		},
		onError: error => dispatch(getComplianceDatesRequestError(error)),
	});
};

export const getComplianceDatesRequest = (propertyId) => ({
	type: GET_COMPLIANCE_DATES_REQUEST,
	payload: {
		propertyId,
		isLoading: true,
		data: null,
	}
});

export const getComplianceDatesRequestSuccess = (data) => ({
	type: GET_COMPLIANCE_DATES_SUCCESS,
	payload: {
		isLoading: false,
		hasError: false,
		data,
		error: null,
	}
});

export const getComplianceDatesRequestError = (error) => ({
	type: GET_COMPLIANCE_DATES_ERROR,
	payload: {
		isLoading: false,
		data: null,
		hasError: true,
		error: error,
	}
});

const complianceDatesReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_COMPLIANCE_DATES_REQUEST:
		case GET_COMPLIANCE_DATES_SUCCESS:
		case GET_COMPLIANCE_DATES_ERROR:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default complianceDatesReducer;
