import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import { getPropertyLinks, getQuickLinks, getMapLinks, getDownloadLinks } from 'utils/propertyLinks'
import Spinner from 'components/Spinner';
import OutboundLink from 'components/OutboundLink';
import AcrisLink from 'pages/overview/AcrisLink';

import { openModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';

function ExternalLinksList(props) {
	const dispatch = useDispatch();
	const {
		isLoading,
		propertyLinks,
		propertyData,
		quickLinks,
		mapLinks,
		downloadLinks,
	} = useSelector(state => ({
		isLoading: state.property.isLoading,
		propertyLinks: getPropertyLinks(state.property.propertyData),
		propertyData: state.property.propertyData,
		quickLinks: getQuickLinks(state.property.propertyData),
		mapLinks: getMapLinks(state.property.propertyData, state.zoning.data),
		downloadLinks: getDownloadLinks(state.property.propertyData),
	}));

	let links = propertyLinks;

	if (props.listName === 'quick') {
		links = quickLinks;
	} else if (props.listName === 'maps') {
		links = mapLinks;
	} else if (props.listName === 'downloads') {
		links = downloadLinks;		
	} else {
		links = Object.assign({}, quickLinks, mapLinks, downloadLinks)
	}

	const openFdnyReportModal = () => {
		if (propertyData) {
			return dispatch(openModal(MODAL_TYPES.FDNY_REPORT));
		}
	}

	return (
		<div>
			{isLoading && <Spinner />}
			{Object.keys(links).map((linkText) => {
				if (props.isLandingPage) {
					return <div key={linkText}><span className="link-disabled">{linkText}</span></div>;
				} if (linkText === 'Acris') {
					return <AcrisLink key={linkText} label="Acris (Deeds, Zoning Declarations & Exhibits)" />
				} else if (linkText === 'FDNY Report') {
					return <button key={linkText} className="btn-hyperlink text-left" disabled={!propertyData} onClick={openFdnyReportModal}>{linkText}</button>
				} else if (!links[linkText]) {
					return <div key={linkText}>{linkText} (Not available)</div>;
				} else if (links[linkText].charAt(0) === '/') { //internal link
					return <div key={linkText}><Link to={links[linkText]}>{linkText}</Link></div>;
				} else {
					return (
						<div key={linkText}>
							<OutboundLink url={links[linkText]} label={linkText} />
						</div>
					);
				}
			})}
		</div>
	);
}

export default ExternalLinksList;
