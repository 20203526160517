import { Link } from "react-router-dom";
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { openModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';
import { fetchPortfolio } from 'reducers/portfolioReducer';
import { removeProperty } from 'reducers/portfolioSettingsReducer';
import { closeModal } from 'reducers/modalReducer';

import Spinner from 'components/Spinner';
import Paginator from 'components/Paginator';

const PortfolioCard = (props) => {
	const dispatch = useDispatch();
	let {
		userId,
		portfolio,
		isLoading,
		currentPage,
		totalPages,
	} = useSelector(state => ({
		userId: state.user.userData?.id,
		portfolio: state.portfolio.portfolioData?.data,
		isLoading: state.portfolio.isLoading || state.portfolioSettings.isRemovingProperty,
		currentPage: state.portfolio.portfolioData?.current_page,
		totalPages: state.portfolio.portfolioData?.last_page,
	}));

	useEffect(() => {
		dispatch(fetchPortfolio(userId));
	}, [userId, dispatch]);

	const openNotificationModal = (portfolioId, property) => {
		dispatch(openModal(MODAL_TYPES.PROPERTY_NOTIFICATION_MODAL, { portfolioId, property }));
	}

	const handleRemoveProperty = (propertyId) => {
		dispatch(removeProperty(propertyId))
		.then((res) => {
			dispatch(fetchPortfolio(userId));
			dispatch(closeModal());
		});
	}

	return (
		<div className="card">
			<div className="card-header">
				Portfolio
			</div>
			<div className="card-body">
				{isLoading && <Spinner />}
				<div className="dashboard-table-wrapper">
					<table className="table table-striped">
						<thead><tr>
							<th>Property Address</th>
							<th>BIN</th>
							<th>Block</th>
							<th>Lot</th>
							<th>Settings</th>
						</tr></thead>
						<tbody>
							{(!portfolio || !portfolio.length) && (
								<tr><td colSpan={5}>Your portfolio is empty</td></tr>
							)}
							{portfolio?.map((item) => (
								<tr key={item.id}>
									<td className="dashboard-table-property-address-cell">
										{item.property?.address ? (
											<Link to={`/property/${item.property?.address}`}>{item.property?.address}</Link>
										) : '-'}
									</td>
									<td>{item.property?.bin || '-'}</td>
									<td>{item.property?.block || '-'}</td>
									<td>{item.property?.lot || '-'}</td>
									<td className="dashboard-table-buttons-cell">
										<button className="btn btn-danger btn-sm me-3" onClick={() => handleRemoveProperty(item.property.id)}>Remove</button>
										<button className="btn btn-primary btn-sm" onClick={() => openNotificationModal(item.id, item.property)}>Notifications</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<Paginator
					currentPage={currentPage}
					totalPages={totalPages}
					setPage={(pageNum) => dispatch(fetchPortfolio(userId, 'address|asc', pageNum, 10))}
				/>
			</div>
		</div>

	);
}

export default PortfolioCard;
