import OverviewCard from 'components/OverviewCard';
import ComplianceDatesChecklist from 'pages/overview/ComplianceDatesChecklist';

// import './ComplianceChecklistCard.css';

function ComplianceChecklistCard(props) {
	return (
		<OverviewCard title="Compliance Checklist">
			<ComplianceDatesChecklist />
		</OverviewCard>
	);
}

export default ComplianceChecklistCard;
