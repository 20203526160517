import { useMediaPredicate } from 'react-media-hook';
import { MOBILE_SIZE } from 'utils/mediaQueryUtils';

import { usePageTitle } from 'utils/pageTitle';
import PropertySearch from 'pages/PropertySearch';
import PropertyOverview from 'pages/PropertyOverview';
import PropertyContainer from 'containers/PropertyContainer';

function LandingPage(props) {
	usePageTitle();
	const isMobile = useMediaPredicate(MOBILE_SIZE);

	if (isMobile) {
		return <PropertySearch />;
	} else {
		return (
			<PropertyContainer>
				<PropertyOverview />
			</PropertyContainer>
		);
	}
}

export default LandingPage;
