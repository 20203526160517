// import { Link } from "react-router-dom";
import { useEffect } from 'react';
import {
	useSelector,
	useDispatch
} from 'react-redux';

import { fetchAdminPortfolios } from 'reducers/adminPortfoliosReducer';
import Spinner from 'components/Spinner';
import Paginator from 'components/Paginator';


const AdminPortfolioManagementCard = (props) => {
	const dispatch = useDispatch();
	let {
		portfolios,
		isLoading,
		currentPage,
		totalPages,
	} = useSelector(state => ({
		portfolios: state.adminPortfolios.data?.data || [],
		isLoading: state.adminPortfolios.isLoading,
		currentPage: state.adminPortfolios.data?.current_page,
		totalPages: state.adminPortfolios.data?.last_page,
	}));

	useEffect(() => {
		dispatch(fetchAdminPortfolios());
	}, [dispatch]);

	return (
		<div className="card">
			<div className="card-header">
				Portfolio Management
			</div>
			<div className="card-body">
				{isLoading && <Spinner />}
				<div className="dashboard-table-wrapper">
					<table className="table table-striped">
						<thead><tr>
							<th>User</th>
							<th>Property</th>
							<th>Created At</th>
						</tr></thead>
						<tbody>
							{portfolios?.map((item) => (
								<tr key={item.id}>
									<td className="dashboard-username-cell">{item.user_slim?.name || '-'}</td>
									<td className="dashboard-table-property-address-cell">{item.property_name?.address || '-'}</td>
									<td className="dashboard-date-time-cell">{item.created_at || '-'}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<Paginator
					currentPage={currentPage}
					totalPages={totalPages}
					setPage={(pageNum) => dispatch(fetchAdminPortfolios('created_at|desc', pageNum, 10))}
				/>
			</div>
		</div>

	);
}

export default AdminPortfolioManagementCard;
