import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!

import { usePageTitle } from 'utils/pageTitle';
import { openModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';
import { fetchComplianceDates } from 'reducers/complianceDatesReducer';

import Spinner from 'components/Spinner';

import './CalendarPage.css';

function complianceDataToEvents(complianceData) {
	let events = [];
	Object.entries(complianceData).forEach(([department, complianceDates]) => {
		complianceDates.forEach(complianceDate => {
			events.push({
				date: complianceDate.date,
				title: `${department} - ${complianceDate.title}`
			});
		});
	});
	return events;
}

const CalendarPage = (props) => {
	const dispatch = useDispatch();
	const {
		propertyId,
		isLoading,
		complianceData,
		propertyData,
	} = useSelector(state => ({
		propertyId: state.property.propertyData?.id,
		isLoading: state.complianceDates.isLoading || state.property.isLoading,
		complianceData: state.complianceDates.data || {},
		propertyData: state.property.propertyData,
	}));
	usePageTitle('Calendar', propertyData);



	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		if (propertyId) {
			dispatch(fetchComplianceDates(propertyId));
		}
	}, [propertyId, dispatch]);

	const onEventClick = (e) => {
		return dispatch(openModal(MODAL_TYPES.CALENDAR_EVENT, { 
			eventTitle: e.event.title,
		}));
	}

	return (
		<div className="calendar-page">
			{isLoading ? <Spinner /> :
				<FullCalendar
					plugins={[ dayGridPlugin ]}
					initialView="dayGridMonth"
					height={500}
					events={complianceDataToEvents(complianceData)}
					eventClick={onEventClick}
				/>
			}
		</div>
	);	
}

export default CalendarPage;
