import FilesWidget from 'components/userFiles/FilesWidget';

const AdminFileCard = (props) => {
	return (
		<div className="card">
			<div className="card-header">
				User Document Administration
			</div>
			<div className="card-body">
				<FilesWidget parentType="admin" />
			</div>
		</div>
	);	
};

export default AdminFileCard;
