import { useEffect } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { useMediaPredicate } from 'react-media-hook';
import { MOBILE_SIZE } from 'utils/mediaQueryUtils';

import { checkAddressAsString } from 'reducers/addressReducer';

import PropertyToolbar from 'components/toolbar/PropertyToolbar';
import Sidebar from 'components/sidebar/Sidebar';
// import SpinnerOverlay from 'components/SpinnerOverlay';

import './PropertyContainer.css';

const PropertyContainer = ({ children }) => {
	const dispatch = useDispatch();
	const isMobile = useMediaPredicate(MOBILE_SIZE);
	const location = useLocation();
	const isLandingPage = location.pathname === '/';
	let { address } = useParams();

	const {
		propertyId,
		isLoadingProperty,
	} = useSelector(state => ({
		propertyId: state.property.propertyData?.id,
		isLoadingProperty: state.property.isLoading,
	}));

	let containerClass = 'main-container';
	if (!isMobile) {
		containerClass += ' has-sidebar';
	}

	useEffect(() => {
		if (!isLandingPage) {
			let decodedAddress = decodeURIComponent(address);
			if (decodedAddress && !propertyId && !isLoadingProperty) {
				dispatch(checkAddressAsString(decodedAddress));
			}			
		}
	}, [address, propertyId, isLoadingProperty, isLandingPage, dispatch]);

	return (
		<div className="app-toolbar-and-body">
			<PropertyToolbar />
			<div className="app-body">
				{(!isMobile) && <Sidebar isLandingPage={isLandingPage} />}
				<div className={containerClass}>
					{children}
				</div>
			</div>
		</div>
	);
};

export default PropertyContainer;
