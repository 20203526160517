import { get, post } from 'data/services/apiService';

const GET_PORTFOLIO_REQUEST = 'GET_PORTFOLIO_REQUEST';
const GET_PORTFOLIO_SUCCESS = 'GET_PORTFOLIO_SUCCESS';
const GET_PORTFOLIO_ERROR = 'GET_PORTFOLIO_ERROR';

const ADD_PROPERTY_TO_PORTFOLIO_REQUEST = 'ADD_PROPERTY_TO_PORTFOLIO_REQUEST';
const ADD_PROPERTY_TO_PORTFOLIO_SUCCESS = 'ADD_PROPERTY_TO_PORTFOLIO_SUCCESS';
const ADD_PROPERTY_TO_PORTFOLIO_ERROR = 'ADD_PROPERTY_TO_PORTFOLIO_ERROR';

const initialState = {
	portfolioData: null,
	isLoading: false,
	error: null,
};

export const fetchPortfolio = (userId, sort='address|asc', page=1, perPage=10) => (dispatch, getState) => {
	let state = getState();
	let url = `https://api.violerts.com/api/portfolio/${userId}?sort=${sort}&page=${page}&per_page=${perPage}`;
	dispatch(getPortfolioRequest());
	return get({
		dispatch,
		url,
		authToken: state.user.authToken,
		onSuccess: (result) => {
			dispatch(getPortfolioSuccess(result))
		},
		onError: error => dispatch(getPortfolioError(error)),
	});
};

export const addPropertyToPortfolio = (propertyId) => (dispatch, getState) => {
	let state = getState();
	let url = `https://api.violerts.com/api/portfolios`;
	dispatch(addPropertyToPortfolioRequest());
	return post({
		dispatch,
		url,
		authToken: state.user.authToken,
		data: {
			property: {
				id: propertyId,
			},
		},
		onSuccess: (result) => {
			dispatch(addPropertyToPortfolioSuccess(result))
		},
		onError: error => dispatch(addPropertyToPortfolioError(error)),
	});
};

export const getPortfolioRequest = () => ({
	type: GET_PORTFOLIO_REQUEST,
	payload: {
		isLoading: true,
		portfolioData: null,
	}
});

export const getPortfolioSuccess = (data) => ({
	type: GET_PORTFOLIO_SUCCESS,
	payload: {
		isLoading: false,
		portfolioData: data,
		error: null,
	}
});

export const getPortfolioError = (error) => ({
	type: GET_PORTFOLIO_ERROR,
	payload: {
		isLoading: false,
		portfolioData: null,
		error: error,
	}
});

export const addPropertyToPortfolioRequest = () => ({
	type: ADD_PROPERTY_TO_PORTFOLIO_REQUEST,
	payload: {
		isLoading: true,
		// portfolioData: null,
	}
});

export const addPropertyToPortfolioSuccess = (data) => ({
	type: ADD_PROPERTY_TO_PORTFOLIO_SUCCESS,
	payload: {
		isLoading: false,
		// portfolioData: data,
		error: null,
	}
});

export const addPropertyToPortfolioError = (error) => ({
	type: ADD_PROPERTY_TO_PORTFOLIO_ERROR,
	payload: {
		isLoading: false,
		// portfolioData: null,
		error: error,
	}
});

const portfolioReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_PORTFOLIO_REQUEST:
		case GET_PORTFOLIO_SUCCESS:
		case GET_PORTFOLIO_ERROR:
		case ADD_PROPERTY_TO_PORTFOLIO_REQUEST:
		case ADD_PROPERTY_TO_PORTFOLIO_SUCCESS:
		case ADD_PROPERTY_TO_PORTFOLIO_ERROR:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default portfolioReducer;
