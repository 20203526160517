import { get } from 'data/services/apiService';

const RECORD_SEARCH_REQUEST = 'RECORD_SEARCH_REQUEST';
const RECORD_SEARCH_SUCCESS = 'RECORD_SEARCH_SUCCESS';
const RECORD_SEARCH_ERROR = 'RECORD_SEARCH_ERROR';

const initialState = {
	dataSet: null,
	searchString: '',
	data: null,
	isLoading: false,
	hasError: false,
	error: null,
};

export const recordSearch = (dataSet, searchString, pageNum=1) => (dispatch) => {
	let url = `https://api.violerts.com/api/open-data/${dataSet}/search?query=${searchString}&page=${pageNum}`;
	dispatch(recordSearchRequest(dataSet, searchString));
	return get({
		dispatch,
		url,
		onSuccess: (result) => {
			dispatch(recordSearchSuccess(result));
		},
		onError: error => dispatch(recordSearchError(error)),
	});
};

export const recordSearchRequest = (dataSet, searchString) => ({
	type: RECORD_SEARCH_REQUEST,
	payload: {
		dataSet,
		searchString,
		data: null,
		isLoading: true,
		hasError: false,
		error: null,
	}
});

export const recordSearchSuccess = (data) => ({
	type: RECORD_SEARCH_SUCCESS,
	payload: {
		data: data,
		isLoading: false,
		hasError: false,
		error: null,
	}
});

export const recordSearchError = (error) => ({
	type: RECORD_SEARCH_ERROR,
	payload: {
		isLoading: false,
		data: null,
		hasError: true,
		error: error,
	}
});

const recordSearchReducer = (state = initialState, action) => {
	switch (action.type) {
		case RECORD_SEARCH_REQUEST:
		case RECORD_SEARCH_SUCCESS:
		case RECORD_SEARCH_ERROR:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default recordSearchReducer;
