import { OPEN_DATA, BIS, CITYPAY, NYC_GOV } from 'data/dataSourceConstants';

export const tableConfig = {
	complaints311: {
		title: '311 Complaints',
		singularTitle: '311 Complaint',
		grouping: 'Complaints',
		dataCategory: 'violation',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Date', key: 'created_date', formatter: 'DateFormatter' },
				{ label: 'Description', key: 'descriptor' },
				{ label: 'Resolution', key: 'resolution_description' },
				{ label: 'Agency', key: 'agency_name' },
				{ label: 'Status', key: 'status', formatter: 'StatusBadge' },
			],
		},
	},
	dobComplaints: {
		title: 'DOB Complaints',
		singularTitle: 'DOB Complaint',
		grouping: 'Complaints',
		dataCategory: 'violation',
		[BIS]: {
			columns: [
				{ label: 'Date', key: 'date_entered', formatter: 'DateFormatter' },
				{ label: 'Complaint #', key: 'complaint_number' },
				// { label: 'Description', key: 'description' },
				{ label: 'Comments', key: 'comments' },
				{ label: 'Category', key: 'category' },
				{ label: 'Status', key: 'status', formatter: 'StatusBadge' },
			],
		},
		[OPEN_DATA]: {
			columns: [
				{ label: 'Complaint #', key: 'complaint_number' },
				{ label: 'Date Entered', key: 'date_entered', formatter: 'DateFormatter' },
				{ label: 'Inspection Date', key: 'inspection_date', formatter: 'DateFormatter' },
				{ label: 'Disposition Date', key: 'disposition_date', formatter: 'DateFormatter' },
				{ label: 'Disposition', key: 'disposition' },
				// { label: 'Category', key: 'complaint_category' },
				// { label: 'Disposition Code', key: 'disposition_code' },
				{ label: 'Status', key: 'status', formatter: 'StatusBadge' },
			],
		},
	},
	dobViolations: {
		title: 'DOB Violations',
		singularTitle: 'DOB Violation',
		isViolation: true,
		dataCategory: 'violation',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Resolve', key: 'number', formatter: 'ResolutionRequest' }, // these are links
				{ label: 'Date', key: 'issue_date', formatter: 'DateFormatter' },
				{ label: 'Violation #', key: 'number' },
				// { label: 'Violation Category', key: 'violation_category' },
				{ label: 'Disposition Comments', key: 'disposition_comments' },
				// { label: 'Status', key: 'status', formatter: 'StatusBadge' },
				{ label: 'Violation Category', key: 'violation_category', formatter: 'StatusBadge' },
			],
		},
		[BIS]: {
			columns: [
				{ label: 'Resolve', key: 'dob_violation_number', formatter: 'ResolutionRequest' }, // these are links
				// { label: 'Date', key: 'issue_date', formatter: 'DateFormatter' },
				{ label: 'Violation #', key: 'dob_violation_number' },
				{ label: 'Device #', key: 'device_number' },
				{ label: 'ECB Violation #', key: 'ecb_violation_number' },
				{ label: 'Description', key: 'description' },
				// { label: 'Infraction Codes', key: 'infraction_codes' },
				{ label: 'Status', key: 'status', formatter: 'StatusBadge' },
			],
		},
	},
	ecbViolations: {
		title: 'ECB Violations',
		singularTitle: 'ECB Violation',
		isViolation: true,
		dataCategory: 'violation',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Resolve', key: 'ecb_violation_number', formatter: 'ResolutionRequest' }, // these are links
				{ label: 'AEU2', key: 'ecb_violation_number', formatter: 'AEU2Link' },
				{ label: 'AEU20', key: 'ecb_violation_number', formatter: 'AEU20Link' },
				{ label: 'Date', key: 'issue_date', formatter: 'DateFormatter' },
				{ label: 'Hearing Date', key: 'hearing_date', formatter: 'CalendarLink' }, //adds to google calendar
				{ label: 'ECB #', key: 'ecb_violation_number' },
				{ label: 'Infra. Code', key: 'infraction_code1', formatter: 'ECBInfractionCode' },
				{ label: 'Description', key: 'violation_description' },
				{ label: 'Respondent Name', key: 'respondent_name' },
				{ label: 'Penalty Imposed', key: 'penality_imposed' },
				{ label: 'Status', key: 'ecb_violation_status', formatter: 'StatusBadge' },
			],
		},
		[BIS]: {
			columns: [
				{ label: 'Resolve', key: 'ecb_violation_number', formatter: 'ResolutionRequest' }, // these are links
				{ label: 'AEU2', key: 'ecb_violation_number', formatter: 'AEU2Link' },
				{ label: 'AEU20', key: 'ecb_violation_number', formatter: 'AEU20Link' },
				// { label: 'Violation Date', key: 'violation_date', formatter: 'DateFormatter' },
				{ label: 'ECB #', key: 'ecb_violation_number' },
				{ label: 'Infra. Code', key: 'infraction_codes', formatter: 'ECBInfractionCode' },
				// { label: 'Description', key: 'specific_violation_conditions' },
				// { label: 'Penalty Imposed', key: 'penalty_imposed' },
				{ label: 'Status', key: 'buildings_violation_status', formatter: 'StatusBadge' },
			],
		},
	},
	jobApplications: {
		title: 'Job Applications',
		singularTitle: 'Job Application',
		grouping: 'Job Applications',
		dataCategory: 'application',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Drawings', key: 'job__', formatter: 'ResolutionRequest' }, // these are links
				{ label: 'Last Action Date', key: 'latest_action_date', formatter: 'DateFormatter' },
				{ label: 'Job #', key: 'job__' },
				{ label: 'First Name', key: 'applicant_s_first_name' },
				{ label: 'Last Name', key: 'applicant_s_last_name' },
				// { label: 'Sign-Offs', key: 'issue_date' },
				// { label: 'Inspection Reports', key: 'issue_date' },
				// { label: 'Items Required', key: 'issue_date' },
				{ label: 'Doc #', key: 'doc__' },
				// { label: 'Description', key: 'job_application_ext.job_description' },
				{ label: 'Status', key: 'job_status_descrp', formatter: 'StatusBadge' }, //wrong field?

			],
		},
		[BIS]: {
			columns: [
				{ label: 'Last Action Date', key: 'status_date', formatter: 'DateFormatter' },
				{ label: 'Job #', key: 'job_number' },
				{ label: 'Doc #', key: 'doc_number' },
				{ label: 'Type', key: 'job_type' },
				// { label: 'Description', key: 'job_description' },
				{ label: 'Applicant', key: 'applicant' },
				{ label: 'Status', key: 'job_status', formatter: 'StatusBadge' }, //wrong field?
			],
		},
	},
	dobPermitIssuances: {
		title: 'DOB Permit Issuances',
		singularTitle: 'DOB Permit Issuance',
		navTitle: 'Permit Issuances',
		// grouping: 'Department of Buildings',
		grouping: 'Job Applications',
		dataCategory: 'application',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Issue Date', key: 'issuance_date', formatter: 'DateFormatter' },
				{ label: 'Job #', key: 'job__' },
				{ label: 'Job Doc', key: 'job_doc___' },
				{ label: 'Permittee', key: 'permittee_s_business_name' },
				{ label: 'Filing Status', key: 'filing_status' },
				{ label: 'Status', key: 'permit_status', formatter: 'StatusBadge' },
			],
		},
	},
	approvedPermits: {
		title: 'DOB NOW Build - Approved Permits',
		singularTitle: 'DOB NOW Build - Approved Permit',
		navTitle: 'Approved Permits',
		// grouping: 'Department of Buildings',
		grouping: 'Job Applications',
		dataCategory: 'application',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Issued Date', key: 'issued_date', formatter: 'DateFormatter' },
				{ label: 'Expiring Date', key: 'expired_date', formatter: 'DateFormatter' },
				{ label: 'Job Filing #', key: 'job_filing_number' },
				{ label: 'Description', key: 'job_description' },
				{ label: 'Applicant', key: 'applicant_business_name' },
			],
		},
	},
	certificatesOfOccupancy: {
		title: 'DOB Certificates of Occupancy',
		singularTitle: 'DOB Certificate of Occupancy',
		navTitle: 'Certificates of Occupancy',
		// grouping: 'Department of Buildings',
		grouping: 'Job Applications',
		dataCategory: 'application',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Issues Date', key: 'c_o_issue_date', formatter: 'DateFormatter' },
				{ label: 'Job #', key: 'job_number' },
				{ label: 'Issue Type', key: 'issue_type' },
				{ label: 'Filing Status', key: 'filing_status_raw' },
			],
		},
	},
	dobJobApplications: {
		title: 'DOB NOW Build - Job Application Filings',
		singularTitle: 'DOB NOW Build - Job Application Filing',
		// navTitle: 'Job Application Filings',
		navTitle: 'Job Applications (Build)',
		// grouping: 'Department of Buildings',
		grouping: 'Job Applications',
		dataCategory: 'application',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Job Filing #', key: 'job_filing_number' },
				{ label: 'Filing Status', key: 'filing_status' },
				{ label: 'Business Name', key: 'owner_s_business_name' },
			],
		},
	},
	dobNowSafetyFacades: {
		title: 'Facade Filings',
		singularTitle: 'Facade Filing',
		grouping: 'Job Applications',
		dataCategory: 'application',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Job Filing #', key: 'tr6_no' },
				{ label: 'Filing Type', key: 'filing_type' },
				{ label: 'Filing Status', key: 'filing_status' },
				{ label: 'Submitted Date', key: 'submitted_on', formatter: 'DateFormatter' },
			],
		},
	},
	dobSignApplicationFilings: {
		title: 'Sign Applications',
		singularTitle: 'Sign Application',
		// grouping: 'Department of Buildings',
		dataCategory: 'application',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Job #', key: 'job__' },
				{ label: 'Job Status', key: 'job_status' },
				{ label: 'Filing Date', key: 'pre_filing_date', formatter: 'DateFormatter' },
			],
		},
	},
	dobNowSafetyBoilers: {
		title: 'Boilers',
		singularTitle: 'Boiler',
		// grouping: 'Department of Buildings',
		dataCategory: 'application',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Boiler ID', key: 'boiler_id' },
				{ label: 'Report Type', key: 'report_type' },
				{ label: 'Inspection Date', key: 'inspection_date', formatter: 'DateFormatter' },
				{ label: 'Report Status', key: 'report_status' },
			],
		},
	},
	electricalPermitApplications: {
		title: 'Electrical',
		singularTitle: 'Electrical',
		dataCategory: 'application',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Job Filing Number', key: 'job_filing_number' },
				{ label: 'Job Number', key: 'job_number' },
				{ label: 'Filing Date', key: 'filing_date', formatter: 'DateFormatter' },
				{ label: 'Filing Status', key: 'filing_status' },
				{ label: 'Job Status', key: 'job_status' },
			],
		},
	},
	elevatorPermitApplications: {
		title: 'Elevator',
		singularTitle: 'Elevator',
		// grouping: 'Department of Buildings',
		dataCategory: 'application',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Job Filing Number', key: 'job_filing_number' },
				{ label: 'Job Number', key: 'job_number' },
				{ label: 'Filing Date', key: 'filing_date', formatter: 'DateFormatter' },
				{ label: 'Filing Type', key: 'filing_type' },
				{ label: 'Filing Status', key: 'filing_status' },
			],
		},
	},
	hpdHousingViolations: {
		title: 'Housing Violations',
		singularTitle: 'Housing Violation',
		grouping: 'HPD',
		isViolation: true,
		dataCategory: 'violation',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Resolve', key: 'violationid', formatter: 'ResolutionRequest' }, // these are links
				{ label: 'Violation ID', key: 'violationid' },
				{ label: 'Issue Date', key: 'novissueddate', formatter: 'DateFormatter' },
				{ label: 'Description', key: 'novdescription' },
				{ label: 'Status', key: 'currentstatus', formatter: 'StatusBadge' },
			],
		},
	},
	hpdHousingComplaints: {
		title: 'Housing Complaints',
		singularTitle: 'Housing Complaint',
		grouping: 'HPD',
		dataCategory: 'violation',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Complaint ID', key: 'complaintid' },
				{ label: 'Date', key: 'receiveddate', formatter: 'DateFormatter' },
				{ label: 'Problems', key: 'statusdescription' }, //todo: this has a custom renderer in legacy vue
				{ label: 'Status', key: 'status', formatter: 'StatusBadge' },
			],
		},
	},
	repairVacateOrders: {
		title: 'HPD Orders to Repair/Vacate',
		singularTitle: 'HPD Order to Repair/Vacate',
		navTitle: 'Orders to Repair/Vacate',
		grouping: 'HPD',
		dataCategory: 'violation',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Resolve', key: 'vacate_order_number', formatter: 'ResolutionRequest' }, // these are links
				{ label: 'Order Number', key: 'vacate_order_number' },
				{ label: 'Date', key: 'vacate_effective_date', formatter: 'DateFormatter' },
				{ label: 'Type', key: 'vacate_type' },
				{ label: 'Reason', key: 'primary_vacate_reason' },
				{ label: 'Units Vacated', key: 'number_of_vacated_units' },
			],
		},
	},
	standardsApplicationStatuses: {
		title: 'Board of Standards and Appeals',
		singularTitle: 'Board of Standards and Appeals',
		dataCategory: 'application',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Date Filed', key: 'filed', formatter: 'DateFormatter' },
				{ label: 'Status', key: 'status' },
				{ label: 'Project Description', key: 'project_description' },
			],
		},
	},
	fdnyViolations: {
		title: 'FDNY - Active Violation Orders',
		singularTitle: 'FDNY - Active Violation Order',
		navTitle: 'Active Violation Orders',
		grouping: 'FDNY',
		isViolation: true,
		dataCategory: 'violation',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Resolve', key: 'violation_num', formatter: 'ResolutionRequest' }, // these are links
				{ label: 'Date', key: 'vio_date', formatter: 'DateFormatter' },
				{ label: 'Violation ID', key: 'vio_id' },
				{ label: 'Violation Number', key: 'violation_num' },
				{ label: 'Description', key: 'vio_law_desc' },
				{ label: 'Status', key: 'action', formatter: 'StatusBadge' },
			],
		},
	},
	fdnyInspections: {
		title: 'FDNY - Inspections',
		singularTitle: 'FDNY - Inspection',
		navTitle: 'Inspections',
		grouping: 'FDNY',
		dataCategory: 'violation',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Date', key: 'last_visit_dt', formatter: 'DateFormatter' },
				{ label: 'Acct ID', key: 'acct_id' },
				{ label: 'Owner Name', key: 'owner_name' },
				{ label: 'Status', key: 'last_insp_stat', formatter: 'StatusBadge' },
			],
		},
	},
	fdnyPermits: {
		title: 'FDNY - Permits',
		singularTitle: 'FDNY - Permit',
		navTitle: 'Permits',
		grouping: 'FDNY',
		isViolation: true,
		dataCategory: 'violation',
		[NYC_GOV]: {
			columns: [
				{ label: 'Resolve', key: 'entityId', formatter: 'ResolutionRequest' }, // these are links
				{ label: 'Date', key: 'displayStatusDate', formatter: 'DateFormatter' },
				{ label: 'Type', key: 'statusAgencyOrType' },
				{ label: 'Account #', key: 'entityId' },
				{ label: 'Category', key: 'statusCategory' },
				{ label: 'PDF', key: 'pdfLink', formatter: 'OutboundLink' },
				{ label: 'Status', key: 'statusCode', formatter: 'StatusBadge' },
			],
		},
	},
	fdnySummaries: {
		navTitle: 'Summary',
		title: 'FDNY - Summary',
		singularTitle: 'FDNY - Summary',
		grouping: 'FDNY',
		dataCategory: 'violation',
		[OPEN_DATA]: {
			columns: [
				{ label: '# Siam Sprinklers', key: 'num_siam_sprinkler' },
				{ label: 'Sprinkler Type', key: 'sprinkler_type' },
				{ label: '# Siam Standpipes', key: 'num_siam_standpipe' },
				{ label: 'Standpipe Type', key: 'standpipe_type' },
				{ label: '# Open Notices of Violation', key: 'num_of_violation' },
				{ label: '# Open Violation Orders', key: 'num_of_violation_1' },
			],
		},
	},
	citypayTickets: {
		title: 'CityPay Tickets',
		singularTitle: 'CityPay Ticket',
		dataCategory: 'violation',
		isViolation: true,
		[CITYPAY]: {
			columns: [
				{ label: 'Resolve', key: 'ticketNumber', formatter: 'ResolutionRequest' }, // these are links
				{ label: 'Vio. Date', key: 'violationDate', formatter: 'DateFormatter' },
				{ label: 'Ticket Number', key: 'ticketNumber' },
				{ label: 'Description', key: 'description' },
				{ label: 'Issuing Agency', key: 'issuingAgency' },
				{ label: 'Status', key: 'status' },
				{ label: 'Total Due', key: 'totalAmountDue' },
			],
		},
	},
	taxLienSales: {
		title: 'Tax Lien Sales',
		singularTitle: 'Tax Lien Sale',
		dataCategory: 'legals',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Date', key: 'month', formatter: 'DateFormatter' },
				{ label: 'Cycle', key: 'cycle' },
				{ label: 'Tax Class Code', key: 'tax_class_code' },
				{ label: 'Building Class', key: 'building_class' },
				{ label: 'Community Board', key: 'community_board' },
				{ label: 'Council District', key: 'council_district' },
			],
		},
	},
	acrisLegals: {
		title: 'ACRIS Legals',
		singularTitle: 'ACRIS Real Property Legal',
		dataCategory: 'legals',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Document ID', key: 'document_id', formatter: 'AcrisRealPropertyMaster' },
				{ label: 'Record Type', key: 'record_type', },
				{ label: 'Easement', key: 'easement', },
				{ label: 'Partial Lot', key: 'partial_lot', },
				{ label: 'Air Rights', key: 'air_rights', },
				{ label: 'Subterranean Rights', key: 'subterranean_rights', },
				{ label: 'Property Type', key: 'property_type', },
				// { label: 'Unit', key: 'unit', },
				{ label: 'Good Through Date', key: 'good_through_date', formatter: 'DateFormatter', },
			],
		},
	},
	dobPlaceOfAssembly: {
		title: 'DOB Place of Assembly',
		singularTitle: 'DOB Place of Assembly',
		dataCategory: 'registration',
		[BIS]: {
			columns: [
				{ label: 'Job #', key: 'jobNumber', },
				{ label: 'File Date', key: 'fileDate', formatter: 'DateFormatter', },
				{ label: 'License Number', key: 'licenseNumber', },
				{ label: 'Applicant', key: 'applicant', },
				{ label: 'Description', key: 'description', },
				{ label: 'PDF', key: 'pdfLink', formatter: 'OutboundLink' },
				{ label: 'Details', key: 'detailsLink', formatter: 'OutboundLink' },
				{ label: 'Job Status', key: 'jobStatus', formatter: 'StatusBadge' },
			],
		},
	},
	slaLiquorLicenses: {
		title: 'SLA Liquor Licenses',
		singularTitle: 'SLA Liquor License',
		dataCategory: 'registration',
		[OPEN_DATA]: {
			columns: [
				{ label: 'DBA', key: 'dba' },
				{ label: 'Premise Name', key: 'premise_name' },
				{ label: 'Method of Op.', key: 'method_of_operation' },
				{ label: 'Other', key: 'other' },
				{ label: 'Issued', key: 'license_issued_date', formatter: 'DateFormatter' },
				{ label: 'Expires', key: 'license_expiration_date', formatter: 'DateFormatter' },
				{ label: 'Class', key: 'license_class_code' },
				{ label: 'Type', key: 'license_type_code' },
				{ label: 'Cert. #', key: 'certificate_number', },
				{ label: 'Serial #', key: 'serial_number' },
			],
		},
	},
	dosBusinessEntities: {
		title: 'DOS Business Entities',
		singularTitle: 'DOS Business Entity',
		dataCategory: 'registration',
		[OPEN_DATA]: {
			columns: [
				{ label: 'Name', key: 'corp_name' },
				{ label: 'Id', key: 'corpid_num' },
				{ label: 'Doc Type', key: 'documenttype' },
				{ label: 'Entity Type', key: 'entitytype' },
				{ label: 'Filed', key: 'date_filed', formatter: 'DateFormatter' },
				// { label: 'Approved', key: 'approved_date', formatter: 'DateFormatter' },
				// { label: 'Effective', key: 'eff_date', formatter: 'DateFormatter' },
			],
		},
	},
};


export default tableConfig;
