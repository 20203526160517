import { useEffect } from 'react';
import { Link } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { useMediaPredicate } from 'react-media-hook';
import { MOBILE_SIZE } from 'utils/mediaQueryUtils';

import { usePageTitle } from 'utils/pageTitle';
import { removeProperty, fetchPortfolioSettingsByPropertyId } from 'reducers/portfolioSettingsReducer';
import { fetchFirstLoadTasks } from 'reducers/firstLoadTasksReducer';
import { addPropertyToPortfolio, fetchPortfolio } from 'reducers/portfolioReducer';
import { checkAddressAsString } from 'reducers/addressReducer';
import { fetchZoningData } from 'reducers/zoningReducer';
import { openModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';

// import Spinner from 'components/Spinner';

import AmountsDue from 'pages/overview/AmountsDue';
import AmountsDueCard from 'pages/overview/AmountsDueCard';
import BuildingInfo from 'pages/overview/BuildingInfo';
import PropertyDocRequestCard from 'pages/overview/PropertyDocRequestCard';
import LinksCard from 'pages/overview/LinksCard';
import ComplianceChecklistCard from 'pages/overview/ComplianceChecklistCard';

import './PropertyOverview.css';

const PropertyOverview = (props) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const isLandingPage = location.pathname === '/';
	let { address } = useParams();

	let {
		isLoading,
		userId,
		propertyData,
		portfolio,
		isLoadingPortfolio,
		isRemovingProperty,
		isLoggedIn,
		propertyId,
	} = useSelector(state => ({
		isLoading: state.property.isLoading || state.address.isLoading,
		userId: state.user.userData?.id,
		propertyData: state.property.propertyData,
		portfolio: state.portfolio.portfolioData?.data || [],
		isLoadingPortfolio: state.portfolio.isLoading,
		isRemovingProperty: state.portfolioSettings.isRemovingProperty,
		isLoggedIn: !!state.user.userData?.id,
		propertyId: state.property.propertyData?.id,
	}));

	usePageTitle(null, propertyData);

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		if (!isLandingPage) {
			let decodedAddress = decodeURIComponent(address);
			if (!isLoading && decodedAddress !== propertyData?.address) {
				dispatch(checkAddressAsString(decodedAddress));
			}
		} else if (isLandingPage && !isLoading) {
			dispatch(openModal(MODAL_TYPES.GET_STARTED));
		}
	}, [address, isLandingPage]);

	useEffect(() => {
		if (!isLandingPage) {
			if (isLoggedIn) {
				dispatch(fetchPortfolio(userId));
			}
		}
	}, [userId, isLandingPage]);

	useEffect(() => {
		if (!isLandingPage) {
			if (propertyId) {
				dispatch(fetchFirstLoadTasks(propertyId));
				dispatch(fetchZoningData(propertyId));
			}
		}
	}, [propertyId, isLandingPage]);

	useEffect(() => {
		if (!isLandingPage) {
			if (propertyId && isLoggedIn) {
				dispatch(fetchPortfolioSettingsByPropertyId(propertyId));
			}
		}
	}, [propertyId, isLoggedIn, isLandingPage]);

	const isMobile = useMediaPredicate(MOBILE_SIZE);
	// const isTablet = useMediaPredicate(TABLET_SIZE);
	let isPropertyInPortfolio = !!portfolio.find(portfolioItem => portfolioItem?.property?.id === propertyData?.id);
	let isTogglingPortfolio = isLoading || !propertyData?.id || isRemovingProperty;

	const handleRemoveProperty = (propertyId) => {
		dispatch(removeProperty(propertyId))
		.then((res) => {
			dispatch(fetchPortfolio(userId));
			dispatch(fetchPortfolioSettingsByPropertyId(propertyId));
		});
	}

	const handleAddProperty = (propertyId) => {
		dispatch(addPropertyToPortfolio(propertyId))
		.then((res) => {
			dispatch(fetchPortfolio(userId));
			dispatch(fetchPortfolioSettingsByPropertyId(propertyId));
		});
	}

	let togglePortfolioButton = (
		<button className={`btn btn-primary btn-sm`} disabled={isTogglingPortfolio} onClick={() => handleAddProperty(propertyData.id)}>
			Add to Portfolio
		</button>
	);

	if (!isLoadingPortfolio && isPropertyInPortfolio) {
		togglePortfolioButton = (
			<button className={`btn btn-danger btn-sm`} disabled={isTogglingPortfolio} onClick={() => handleRemoveProperty(propertyData.id)}>
				Remove from Portfolio
			</button>
		);
	}

	let overviewButtons = (
		<div className="button-wrapper">
			{isLoggedIn && !isLandingPage && (
				<button
					className="btn btn-primary btn-sm"
					onClick={() => dispatch(openModal(MODAL_TYPES.PROPERTY_FILE_UPLOAD_MODAL, {
						propertyId,
						address: propertyData?.address,
					}))}
				>
						Upload File
				</button>
			)}
			{isLoggedIn && togglePortfolioButton}
			{isMobile && <button
				className={`btn btn-outline-primary btn-sm`}
				onClick={() => dispatch(openModal(MODAL_TYPES.EXPORT_REPORT))}
				disabled={isLandingPage}
			>
				Export Report
			</button>}
		</div>
	);


	return (
		<div className="property-overview">
			{!isMobile && 
				<div className="overview-header">
					<div className="overview-title">
						<h2>{propertyData?.address || ''}</h2>
					</div>
					{overviewButtons}
				</div>
			}

			<BuildingInfo propertyId={propertyId} />
			{isMobile ? <AmountsDueCard /> : <AmountsDue />}

			{!isMobile && (
				<div className="overview-cards-container">
					{/*<AmountsDueCard isLandingPage={isLandingPage} />*/}
					<LinksCard isLandingPage={isLandingPage} title="Quick Links" listName='quick' />
					<LinksCard isLandingPage={isLandingPage} title="Document Downloads" listName='downloads' />
					<ComplianceChecklistCard isLandingPage={isLandingPage} />
					<LinksCard isLandingPage={isLandingPage} title="Maps" listName='maps' />
					<PropertyDocRequestCard isLandingPage={isLandingPage} />
				</div>
			)}

			{isMobile && overviewButtons}
			<div className="use-policy-wrapper"><Link to="/use-policy">Acceptable Use Policy</Link></div>
		</div>
	);	
}

export default PropertyOverview;
