import { BOROUGHS } from 'utils/boroughs';

//violerts key
// export const GOOGLE_MAPS_API_KEY = 'AIzaSyCcMHBbLvpK26FhSwALwzJMxpEECnmLhbs';

// new violerts key
// export const GOOGLE_MAPS_API_KEY = 'AIzaSyCdQqfyfS8qGJqpWVvp-jK1rfg9IJhye6s';

//new new key
export const GOOGLE_MAPS_API_KEY = 'AIzaSyB16pyBgKK-CUFNTmajG_pjseOeCuZpljk';


const HOUSE_NUM_INDEX = 0;
const STREET_NAME_INDEX = 1;
const MANHATTAN_BOROUGH_INDEX = 2;
const OUTER_BOROUGH_INDEX = 3;

export const getHouseNumber = (place) => {
	return place.address_components[HOUSE_NUM_INDEX].long_name;
};

export const getStreetName = (place) => {
	return place.address_components[STREET_NAME_INDEX].long_name;
};

export const getBoroughNum = (place) => {
	if (place.address_components[MANHATTAN_BOROUGH_INDEX].long_name === 'Manhattan') {
		return BOROUGHS.MANHATTAN;
	}
	if (place.address_components[OUTER_BOROUGH_INDEX].long_name === 'Bronx') {
		return BOROUGHS.BRONX;
	}
	if (place.address_components[OUTER_BOROUGH_INDEX].long_name === 'Brooklyn') {
		return BOROUGHS.BROOKLYN;
	}
	if (place.address_components[OUTER_BOROUGH_INDEX].long_name === 'Queens') {
		return BOROUGHS.QUEENS;
	}
	if (place.address_components[OUTER_BOROUGH_INDEX].long_name === 'Staten Island') {
		return BOROUGHS.STATEN_ISLAND;
	}

	return -1; //todo: this is sort of an error state, handle it
};

export const getAddressWithoutUSA = (place) => {
	return place.formatted_address.replace(', USA', '');
}
