export const initDarkMode = () => {
	let body = document.body;
	let isLightMode = localStorage.getItem('lightMode');
	if (isLightMode) {
		body.classList.remove('dark-mode');
	} else {
		body.classList.add('dark-mode');
	}
}

export const toggleDarkMode = () => {
	let body = document.body;

	let isDarkMode = body.classList.contains('dark-mode');
	if (isDarkMode) {
		localStorage.removeItem('darkMode');
		localStorage.setItem('lightMode', true);
		body.classList.remove('dark-mode');
	} else {
		localStorage.setItem('darkMode', true);
		localStorage.removeItem('lightMode');
		body.classList.add('dark-mode');
	}
}